import React, { useState, useRef, useEffect } from "react";
import LStyles from "../styles/library.module.css";


const ImageCropper = ({ on_crop_complete }) => {
    //DATA STORAGE
    const [draggingPoint, setDraggingPoint] = useState(null);
    const [draggingOver, setDraggingOver] = useState(null);

    const [frontCoverImageSrc, setFrontCoverImageSrc] = useState(null);
    const [backCoverImageSrc, setBackCoverImageSrc] = useState(null);
    const [pubPageImageSrc, setPubPageImageSrc] = useState(null);

    const [frontCoverCropCoords, setFrontCoverCropCoords] = useState({ x1: null, y1: null, x2: null, y2: null });
    const [backCoverCropCoords, setBackCoverCropCoords] = useState({ x1: null, y1: null, x2: null, y2: null });
    const [pubPageCropCoords, setPubPageCropCoords] = useState({ x1: null, y1: null, x2: null, y2: null });

    const frontCoverCanvasRef = useRef(null);
    const backCoverCanvasRef = useRef(null);
    const pubPageCanvasRef = useRef(null);

    const frontCoverImageRef = useRef(new Image());
    const backCoverImageRef = useRef(new Image());
    const pubPageImageRef = useRef(new Image());

    //IMAGE SELECTION FUNCTIONS
    const handle_image_upload = (file, whichPage) => {
        console.log("In handle_image_upload: " + whichPage);

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                switch(whichPage) {
                    case "front_cover":
                        //Clear any existing crop selection
                        setFrontCoverCropCoords({ x1: null, y1: null, x2: null, y2: null });
                        setFrontCoverImageSrc(reader.result);
                        break;
                    case "back_cover":
                        setBackCoverCropCoords({ x1: null, y1: null, x2: null, y2: null });
                        setBackCoverImageSrc(reader.result);
                        break;
                    case "pub_page":
                        setPubPageImageSrc(reader.result);
                        setPubPageCropCoords({ x1: null, y1: null, x2: null, y2: null });
                        break;
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const scale_image_source = (canvas_ref, image_src, image_ref) => {
        console.log("In scale_image_source: " + image_src);

        if (!image_src || !canvas_ref.current) return;

        const canvas = canvas_ref.current;
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = image_src;

        img.onload = () => {
            const maxWidth = 800;
            const scale = Math.min(maxWidth / img.width, 1);
            const newWidth = img.width * scale;
            const newHeight = img.height * scale;

            // setCanvasSize({ width: newWidth, height: newHeight });

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.clearRect(0, 0, newWidth, newHeight);
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            image_ref.current = img;
        };
    }
    // Ensure correct scaling when image loads
    useEffect(() => {
        scale_image_source(frontCoverCanvasRef, frontCoverImageSrc, frontCoverImageRef);
    }, [frontCoverImageSrc]);
    useEffect(() => {
        scale_image_source(backCoverCanvasRef, backCoverImageSrc, backCoverImageRef);
    }, [backCoverImageSrc]);
    useEffect(() => {
        scale_image_source(pubPageCanvasRef, pubPageImageSrc, pubPageImageRef);
    }, [pubPageImageSrc]);

    //CLIENT SIDE CROPPING OF IMAGES AND UPLOADING
    const crop_and_upload_files = async () => {
        console.log("In cropping and uploading files");

        let images_to_crop = [];
        let cropped_blobs = {};

        if(frontCoverImageSrc) {
            images_to_crop.push({
                    "which_page": "front_cover",
                    "image_ref" : frontCoverImageRef,
                    "crop_coords": frontCoverCropCoords
                });
            console.log("We have a front cover.");
        }
        if(backCoverImageSrc) {
            images_to_crop.push({
                "which_page": "back_cover",
                "image_ref" : backCoverImageRef,
                "crop_coords": backCoverCropCoords
            });
            console.log("We have a back cover.");
        }
        if(pubPageImageSrc) {
            images_to_crop.push({
                "which_page": "pub_page",
                "image_ref" : pubPageImageRef,
                "crop_coords": pubPageCropCoords
            });
            console.log("We have a pub page.");
        }

        const crop_promises = images_to_crop.map((item, index) => {
            return new Promise((resolve) => {
                const img = item.image_ref.current;
                const { x1, y1, x2, y2 } = item.crop_coords;
                const width = x2 - x1;
                const height = y2 - y1;

                // Create a new canvas for the cropped image
                const cropCanvas = document.createElement("canvas");
                const ctx = cropCanvas.getContext("2d");

                cropCanvas.width = width;
                cropCanvas.height = height;

                // Draw the cropped portion onto the new canvas
                ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);

                // Convert the cropped portion to a Blob and upload it
                cropCanvas.toBlob((blob) => {
                    cropped_blobs[item.which_page] = blob;
                    resolve();
                }, "image/jpeg");
            });
        });

        await Promise.all(crop_promises);

        on_crop_complete(cropped_blobs);

        // console.log(cropped_blobs);
        // let final_blobs = Object.fromEntries(
        //     cropped_blobs).filter(([_, blob]) => blob);

        //console.log("Cropped Images Ready:", final_blobs);
    }
    const handle_crop = (image_ref, crop_coords) => {
        if (!image_ref.current || crop_coords.x2 === null) return;

        const img = image_ref.current;
        const { x1, y1, x2, y2 } = crop_coords;
        const width = x2 - x1;
        const height = y2 - y1;

        // Create a new canvas for the cropped image
        const cropCanvas = document.createElement("canvas");
        const ctx = cropCanvas.getContext("2d");

        cropCanvas.width = width;
        cropCanvas.height = height;

        // Draw the cropped portion onto the new canvas
        ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);

        // Convert the cropped portion to a Blob and upload it
        cropCanvas.toBlob((blob) => on_crop_complete(blob), "image/jpeg");
    }

    // Handle cropping the image on the client side
    // const crop_image_client_side = () => {
    //     console.log("In crop_image_client_side");
    //
    //     if (!imageRef.current || cropCoords.x2 === null) return;
    //
    //     const img = imageRef.current;
    //     const { x1, y1, x2, y2 } = cropCoords;
    //     const width = x2 - x1;
    //     const height = y2 - y1;
    //
    //     // Create a new canvas for the cropped image
    //     const cropCanvas = document.createElement("canvas");
    //     const ctx = cropCanvas.getContext("2d");
    //
    //     cropCanvas.width = width;
    //     cropCanvas.height = height;
    //
    //     // Draw the cropped portion onto the new canvas
    //     ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);
    //
    //     // Convert the cropped portion to a Blob and upload it
    //     cropCanvas.toBlob((blob) => on_crop_complete(blob), "image/jpeg");
    // };


    //DRAWING THE CROP BOXES
    const draw_crop_box = (image_src, canvas_ref, image_ref, crop_coords) => {
        console.log("In draw_crop_box");
        console.log(crop_coords);

        if (!image_src || !canvas_ref.current) return;
        const canvas = canvas_ref.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image_ref.current, 0, 0, canvas.width, canvas.height);

        if (crop_coords.x1 !== null) {
            // Convert crop coordinates to displayed size
            const scaleX = canvas.width / image_ref.current.width;
            const scaleY = canvas.height / image_ref.current.height;

            const scaledX1 = crop_coords.x1 * scaleX;
            const scaledY1 = crop_coords.y1 * scaleY;
            const scaledX2 = crop_coords.x2 ? crop_coords.x2 * scaleX : scaledX1;
            const scaledY2 = crop_coords.y2 ? crop_coords.y2 * scaleY : scaledY1;

            // Draw crop rectangle
            if (crop_coords.x2 !== null) {
                ctx.strokeStyle = "white";
                ctx.lineWidth = 2;
                ctx.strokeRect(scaledX1, scaledY1, scaledX2 - scaledX1, scaledY2 - scaledY1);

                // Draw second selection point (red dot)
                ctx.fillStyle = "red";
                ctx.beginPath();
                ctx.arc(scaledX2, scaledY2, 5, 0, Math.PI * 2);
                ctx.fill();
            }

            // Draw first selection point (blue dot)
            ctx.fillStyle = "blue";
            ctx.beginPath();
            ctx.arc(scaledX1, scaledY1, 5, 0, Math.PI * 2);
            ctx.fill();
        }
    }
    useEffect(() => {
        draw_crop_box(frontCoverImageSrc, frontCoverCanvasRef, frontCoverImageRef, frontCoverCropCoords);
    }, [frontCoverCropCoords]);
    useEffect(() => {
        draw_crop_box(backCoverImageSrc, backCoverCanvasRef, backCoverImageRef, backCoverCropCoords);
    }, [backCoverCropCoords]);
    useEffect(() => {
        draw_crop_box(pubPageImageSrc, pubPageCanvasRef, pubPageImageRef, pubPageCropCoords);
    }, [pubPageCropCoords]);
    // Convert click coordinates to original image size
    const handle_canvas_click = (event, which_page) => {
        console.log("In handle_canvas_click: " + which_page);

        let image_ref =
            which_page === 'front_cover' ?
                frontCoverImageRef :
            which_page === 'back_cover' ?
                backCoverImageRef :
                pubPageImageRef;

        let canvas_ref =
            which_page === 'front_cover' ?
                frontCoverCanvasRef :
                which_page === 'back_cover' ?
                    backCoverCanvasRef :
                    pubPageCanvasRef;


        if (!image_ref.current || draggingPoint !== null) return;

        const rect = canvas_ref.current.getBoundingClientRect();
        const scaleX = image_ref.current.width / rect.width;
        const scaleY = image_ref.current.height / rect.height;

        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        console.log("ScaleX = " + scaleX + ", ScaleY = " + scaleY);

        console.log("(X=" + x + ", Y=" + y + ")");

        let crop_coords = null
        let crop_coords_setter = null;
        switch(which_page) {
            case "front_cover":
                console.log("Front Cover Coords");
                crop_coords = frontCoverCropCoords;
                crop_coords_setter = setFrontCoverCropCoords;
                break;
            case "back_cover":
                crop_coords = backCoverCropCoords;
                crop_coords_setter = setBackCoverCropCoords;
                break;
            case "pub_page":
                crop_coords = pubPageCropCoords;
                crop_coords_setter = setPubPageCropCoords;
                break;
        }
        console.log(crop_coords);

        if(crop_coords === null) {
            return;
        }

        if (crop_coords.x1 === null) {
            console.log("Setting first position");
            //crop_coords_setter({ x1: x, y1: y, x2: null, y2: null });
            crop_coords_setter({ x1: x, y1: y, x2: null, y2: null });
        } else if (crop_coords.x2 === null) {
            console.log("Setting second dimension.");
            //crop_coords_setter({ ...cropCoords, x2: x, y2: y });
            crop_coords_setter({ ...crop_coords, x2: x, y2: y });
        }
    };
    // Detect dragging on selection points
    const handle_mouse_down = (event, which_page) => {
        console.log("In handle_mouse_down: " + which_page);
        let image_ref = {
            "front_cover" : frontCoverImageRef,
            "back_cover" : backCoverImageRef,
            "pub_page" : pubPageImageRef }
            [which_page] || null;
        let canvas_ref = {
            "front_cover" : frontCoverCanvasRef,
            "back_cover" : backCoverCanvasRef,
            "pub_page" : pubPageCanvasRef }
            [which_page] || null;
        let crop_coords = {
            "front_cover" : frontCoverCropCoords,
            "back_cover" : backCoverCropCoords,
            "pub_page" : pubPageCropCoords }
            [which_page] || null;


        if (!image_ref.current || !canvas_ref.current || !crop_coords) return;


        const rect = canvas_ref.current.getBoundingClientRect();
        const scaleX = image_ref.current.width / rect.width;
        const scaleY = image_ref.current.height / rect.height;

        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        const threshold = 10 * scaleX;

        if (crop_coords.x1 !== null && Math.abs(x - crop_coords.x1) < threshold && Math.abs(y - crop_coords.y1) < threshold) {
            setDraggingPoint("start");
        } else if (crop_coords.x2 !== null && Math.abs(x - crop_coords.x2) < threshold && Math.abs(y - crop_coords.y2) < threshold) {
            setDraggingPoint("end");
        }
    };
    // Adjust crop coordinates when dragging
    const handle_mouse_move = (event, which_page) => {
        let image_ref = {
            "front_cover" : frontCoverImageRef,
            "back_cover" : backCoverImageRef,
            "pub_page" : pubPageImageRef }
            [which_page] || null;
        let canvas_ref = {
            "front_cover" : frontCoverCanvasRef,
            "back_cover" : backCoverCanvasRef,
            "pub_page" : pubPageCanvasRef }
            [which_page] || null;
        let crop_coords_setter = {
            "front_cover" : setFrontCoverCropCoords,
            "back_cover" : setBackCoverCropCoords,
            "pub_page" : setPubPageCropCoords }
            [which_page] || null;

        if (!image_ref.current || draggingPoint === null) return;

        const rect = canvas_ref.current.getBoundingClientRect();
        const scaleX = image_ref.current.width / rect.width;
        const scaleY = image_ref.current.height / rect.height;

        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        if (draggingPoint === "start") {
            crop_coords_setter((prev) => ({ ...prev, x1: x, y1: y }));
        } else if (draggingPoint === "end") {
            crop_coords_setter((prev) => ({ ...prev, x2: x, y2: y }));
        }
    };
    const handle_mouse_up = (event, which_page) => {
        console.log("In handle_mouse_up");
        setDraggingPoint(null);
    };

    // Handle drag-and-drop
    const handle_drag_over = (event, section) => {
        console.log("In handle_drag_over");
        event.preventDefault();
        setDraggingOver(section);
    };
    const handle_drag_leave = () => {
        console.log("In handle_drag_leave");
        setDraggingOver(null);
    };
    const handle_drop = (event, section) => {
        console.log("In handle_drop");
        event.preventDefault();
        setDraggingOver(null);
        const file = event.dataTransfer.files[0];
        handle_image_upload(file, section);
    };

    // Crop and upload image
    // const crop_image = () => {
    //     console.log("In crop_image");
    //     if (!imageSrc || !canvasRef.current || cropCoords.x2 === null) return;
    //
    //     const canvas = document.createElement("canvas");
    //     const ctx = canvas.getContext("2d");
    //
    //     const img = imageRef.current;
    //     const { x1, y1, x2, y2 } = cropCoords;
    //     const width = x2 - x1;
    //     const height = y2 - y1;
    //
    //     canvas.width = width;
    //     canvas.height = height;
    //
    //     ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);
    //
    //     canvas.toBlob((blob) => on_crop_complete(blob), "image/jpeg");
    // };

    return (
        <>
        {/*<div>*/}
        {/*    <input type="file" accept="image/*" onChange={handle_image_upload} />*/}
        {/*    <div*/}
        {/*        style={{*/}
        {/*            position: "relative",*/}
        {/*            display: imageSrc ? "block" : "none",*/}
        {/*            border: "1px solid black",*/}
        {/*            marginTop: "10px",*/}
        {/*        }}*/}
        {/*        onMouseDown={handle_mouse_down}*/}
        {/*        onMouseMove={handle_mouse_move}*/}
        {/*        onMouseUp={handle_mouse_up}*/}
        {/*        onClick={handle_canvas_click}*/}
        {/*    >*/}
        {/*        <canvas ref={canvasRef} style={{ width: "100%" }} />*/}
        {/*    </div>*/}
        {/*    /!*<button onClick={crop_image_client_side} disabled={cropCoords.x2 === null}>*!/*/}
        {/*    /!*    Crop & Upload*!/*/}
        {/*    /!*</button>*!/*/}
        {/*</div>*/}
        <div className={LStyles.cropper_container}>
            <h2>Upload Book Images</h2>
            {["front_cover", "back_cover", "pub_page"].map((section) => (
                <div
                    key={section}
                    className={`${LStyles.upload_area} `}
                    onDragOver={(e) => handle_drag_over(e, section)}
                    onDragLeave={handle_drag_leave}
                    onDrop={(e) => handle_drop(e, section)}
                > {/*${activeCanvas === section ? LStyles.active : ""}*/}
                    <label className={LStyles.upload_label}>
                        {section === "front_cover" ? "Front Cover" : section === "back_cover" ? "Back Cover" : "Publication Page"}
                        {/* {!images[section] && (  */}
                            <input type="file" accept="image/*" onChange={(e) => handle_image_upload(e.target.files[0], section)} hidden />
                        {/* )}  */}
                    </label>
                    {/*{images[section] && (*/}
                    {/*    <div className={LStyles.upload_controls}>*/}
                    {/*        <span>✅ Image uploaded</span>*/}
                    {/*        <button className={LStyles.remove_button} onClick={() => handle_remove_image(section)}>*/}
                    {/*            Remove*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            ))}
            <div
                className={LStyles.canvas_container}
                onMouseDown={(event) => handle_mouse_down(event, 'front_cover')}
                onMouseMove={(event) => handle_mouse_move(event, "front_cover")}
                onMouseUp={(event) => handle_mouse_up(event, "front_cover")}
                onClick={(event) => handle_canvas_click(event, 'front_cover')}
            >
                {frontCoverCanvasRef && (
                    <canvas
                        ref={frontCoverCanvasRef}
                        className={LStyles.crop_canvas}
                        onClick={(event) => handle_canvas_click(event, 'front_cover')}
                    />
                )}
            </div>
            <div
                className={LStyles.canvas_container}
                onMouseDown={(event) => handle_mouse_down(event, 'back_cover')}
                onMouseMove={(event) => handle_mouse_move(event, "back_cover")}
                onMouseUp={(event) => handle_mouse_up(event, "back_cover")}
                onClick={(event) => handle_canvas_click(event, 'back_cover')}
            >
                {backCoverCanvasRef && (
                    <canvas
                        ref={backCoverCanvasRef}
                        className={LStyles.crop_canvas}
                        onClick={(event) => handle_canvas_click(event, 'back_cover')}
                    />
                )}
            </div>
            <div
                className={LStyles.canvas_container}
                onMouseDown={(event) => handle_mouse_down(event, 'pub_page')}
                onMouseMove={(event) => handle_mouse_move(event, "pub_page")}
                onMouseUp={(event) => handle_mouse_up(event, "pub_page")}
                onClick={(event) => handle_canvas_click(event, 'pub_page')}
            >
                {pubPageCanvasRef && (
                    <canvas
                        ref={pubPageCanvasRef}
                        className={LStyles.crop_canvas}
                        onClick={(event) => handle_canvas_click(event, 'pub_page')}
                    />
                )}
            </div>
            <button onClick={crop_and_upload_files} className={LStyles.form_button}>
                Submit Cropped Data
            </button>
        </div>
        </>
    );




};

export default ImageCropper;


// import React, { useState, useRef, useEffect } from "react";
// import LStyles from "../styles/library.module.css";
//
// const ImageCropper = ({ on_crop_complete }) => {
//     const [images, setImages] = useState({ front: null, back: null, publication: null });
//     const [cropCoords, setCropCoords] = useState({
//         front: { x1: null, y1: null, x2: null, y2: null },
//         back: { x1: null, y1: null, x2: null, y2: null },
//         publication: { x1: null, y1: null, x2: null, y2: null }
//     });
//
//     const [activeCanvas, setActiveCanvas] = useState("front");
//     const [draggingPoint, setDraggingPoint] = useState(null);
//     const canvasRefs = { front: useRef(null), back: useRef(null), publication: useRef(null) };
//     const imageRefs = { front: useRef(new Image()), back: useRef(new Image()), publication: useRef(new Image()) };
//
//     // Prevent default drag behavior
//     useEffect(() => {
//         const preventDefaults = (e) => e.preventDefault();
//         document.addEventListener("dragover", preventDefaults);
//         document.addEventListener("drop", preventDefaults);
//
//         return () => {
//             document.removeEventListener("dragover", preventDefaults);
//             document.removeEventListener("drop", preventDefaults);
//         };
//     }, []);
//
//     // Handle file selection
//     const handle_image_upload = (file, section) => {
//         if (file && !images[section]) {
//             const reader = new FileReader();
//             reader.onload = () => {
//                 setImages((prev) => ({ ...prev, [section]: reader.result }));
//             };
//             reader.readAsDataURL(file);
//         }
//     };
//
//     // Remove image and reset state
//     const handle_remove_image = (section) => {
//         setImages((prev) => ({ ...prev, [section]: null }));
//         setCropCoords((prev) => ({ ...prev, [section]: { x1: null, y1: null, x2: null, y2: null } }));
//     };
//
//     // Draw image when it loads
//     useEffect(() => {
//         if (!images[activeCanvas] || !canvasRefs[activeCanvas].current) return;
//
//         const canvas = canvasRefs[activeCanvas].current;
//         const ctx = canvas.getContext("2d");
//         const img = new Image();
//         img.src = images[activeCanvas];
//
//         img.onload = () => {
//             const maxWidth = 800;
//             const scale = Math.min(maxWidth / img.width, 1);
//             const newWidth = img.width * scale;
//             const newHeight = img.height * scale;
//
//             canvas.width = newWidth;
//             canvas.height = newHeight;
//
//             ctx.clearRect(0, 0, newWidth, newHeight);
//             ctx.drawImage(img, 0, 0, newWidth, newHeight);
//
//             imageRefs[activeCanvas].current = img;
//             draw_crop_box(ctx, activeCanvas, newWidth, newHeight);
//         };
//     }, [images, activeCanvas, cropCoords]);
//
//     // Draw crop box and selection points
//     const draw_crop_box = (ctx, section, canvasWidth, canvasHeight) => {
//         const img = imageRefs[section].current;
//         if (!img || !cropCoords[section].x1) return;
//
//         const { x1, y1, x2, y2 } = cropCoords[section];
//
//         const scaleX = canvasWidth / img.width;
//         const scaleY = canvasHeight / img.height;
//
//         const scaledX1 = x1 * scaleX;
//         const scaledY1 = y1 * scaleY;
//         const scaledX2 = x2 ? x2 * scaleX : scaledX1;
//         const scaledY2 = y2 ? y2 * scaleY : scaledY1;
//
//         // Draw selection box
//         if (x2 !== null) {
//             ctx.strokeStyle = "white";
//             ctx.lineWidth = 2;
//             ctx.strokeRect(scaledX1, scaledY1, scaledX2 - scaledX1, scaledY2 - scaledY1);
//
//             // Draw second selection point (red dot)
//             ctx.fillStyle = "red";
//             ctx.beginPath();
//             ctx.arc(scaledX2, scaledY2, 5, 0, Math.PI * 2);
//             ctx.fill();
//         }
//
//         // Draw first selection point (blue dot)
//         ctx.fillStyle = "blue";
//         ctx.beginPath();
//         ctx.arc(scaledX1, scaledY1, 5, 0, Math.PI * 2);
//         ctx.fill();
//     };
//
//     // Convert click coordinates to original image size
//     const handle_canvas_click = (event) => {
//         if (!imageRefs[activeCanvas].current || draggingPoint !== null) return;
//
//         const rect = canvasRefs[activeCanvas].current.getBoundingClientRect();
//         const scaleX = imageRefs[activeCanvas].current.width / rect.width;
//         const scaleY = imageRefs[activeCanvas].current.height / rect.height;
//
//         const x = (event.clientX - rect.left) * scaleX;
//         const y = (event.clientY - rect.top) * scaleY;
//
//         setCropCoords((prev) => {
//             const coords = prev[activeCanvas];
//             if (coords.x1 === null) {
//                 return { ...prev, [activeCanvas]: { x1: x, y1: y, x2: null, y2: null } };
//             } else if (coords.x2 === null) {
//                 return { ...prev, [activeCanvas]: { ...coords, x2: x, y2: y } };
//             }
//             return prev;
//         });
//     };
//
//     // Crop images before sending
//     const submit_cropped_images = () => {
//         on_crop_complete(cropCoords);
//     };
//
//     return (
//         <div className={LStyles.cropper_container}>
//             <h2>Upload Book Images</h2>
//             {["front", "back", "publication"].map((section) => (
//                 <div key={section} className={`${LStyles.upload_area} ${activeCanvas === section ? LStyles.active : ""}`}>
//                     <label className={LStyles.upload_label}>
//                         {section === "front" ? "Front Cover" : section === "back" ? "Back Cover" : "Publication Page"}
//                         {!images[section] && (
//                             <input type="file" accept="image/*" onChange={(e) => handle_image_upload(e.target.files[0], section)} hidden />
//                         )}
//                     </label>
//                     {images[section] && (
//                         <div className={LStyles.upload_controls}>
//                             <span>✅ Image uploaded</span>
//                             <button className={LStyles.remove_button} onClick={() => handle_remove_image(section)}>
//                                 Remove
//                             </button>
//                         </div>
//                     )}
//                 </div>
//             ))}
//             <div className={LStyles.canvas_container}>
//                 {images[activeCanvas] && (
//                     <canvas ref={canvasRefs[activeCanvas]} className={LStyles.crop_canvas} onClick={handle_canvas_click} />
//                 )}
//             </div>
//             <button onClick={submit_cropped_images} className={LStyles.submit_button}>
//                 Submit Cropped Data
//             </button>
//         </div>
//     );
// };
//
// export default ImageCropper;
//
//
// // import React, { useState, useRef, useEffect } from "react";
// // import LStyles from "./styles/library.module.css";
// //
// //
// // const ImageCropper = ({ on_crop_complete }) => {
// //     const [imageSrc, setImageSrc] = useState(null);
// //     const [cropCoords, setCropCoords] = useState({ x1: null, y1: null, x2: null, y2: null });
// //     const [canvasSize, setCanvasSize] = useState({ width: 800, height: 800 });
// //     const [draggingPoint, setDraggingPoint] = useState(null);
// //     const canvasRef = useRef(null);
// //     const imageRef = useRef(new Image());
// //
// //     // Handle image selection
// //     const handle_image_upload = (event) => {
// //         const file = event.target.files[0];
// //         if (file) {
// //             const reader = new FileReader();
// //             reader.onload = () => {
// //                 setImageSrc(reader.result);
// //             };
// //             reader.readAsDataURL(file);
// //         }
// //     };
// //
// //     // Ensure correct scaling when image loads
// //     useEffect(() => {
// //         if (!imageSrc || !canvasRef.current) return;
// //
// //         const canvas = canvasRef.current;
// //         const ctx = canvas.getContext("2d");
// //         const img = new Image();
// //         img.src = imageSrc;
// //
// //         img.onload = () => {
// //             const maxWidth = 800;
// //             const scale = Math.min(maxWidth / img.width, 1);
// //             const newWidth = img.width * scale;
// //             const newHeight = img.height * scale;
// //
// //             setCanvasSize({ width: newWidth, height: newHeight });
// //
// //             canvas.width = newWidth;
// //             canvas.height = newHeight;
// //
// //             ctx.clearRect(0, 0, newWidth, newHeight);
// //             ctx.drawImage(img, 0, 0, newWidth, newHeight);
// //
// //             imageRef.current = img;
// //         };
// //     }, [imageSrc]);
// //
// //     // Handle cropping the image on the client side
// //     const crop_image_client_side = () => {
// //         if (!imageRef.current || cropCoords.x2 === null) return;
// //
// //         const img = imageRef.current;
// //         const { x1, y1, x2, y2 } = cropCoords;
// //         const width = x2 - x1;
// //         const height = y2 - y1;
// //
// //         // Create a new canvas for the cropped image
// //         const cropCanvas = document.createElement("canvas");
// //         const ctx = cropCanvas.getContext("2d");
// //
// //         cropCanvas.width = width;
// //         cropCanvas.height = height;
// //
// //         // Draw the cropped portion onto the new canvas
// //         ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);
// //
// //         // Convert the cropped portion to a Blob and upload it
// //         cropCanvas.toBlob((blob) => on_crop_complete(blob), "image/jpeg");
// //     };
// //
// //     // Draw crop box and selection points
// //     useEffect(() => {
// //         if (!imageSrc || !canvasRef.current) return;
// //         const canvas = canvasRef.current;
// //         const ctx = canvas.getContext("2d");
// //
// //         ctx.clearRect(0, 0, canvas.width, canvas.height);
// //         ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
// //
// //         if (cropCoords.x1 !== null) {
// //             // Convert crop coordinates to displayed size
// //             const scaleX = canvas.width / imageRef.current.width;
// //             const scaleY = canvas.height / imageRef.current.height;
// //
// //             const scaledX1 = cropCoords.x1 * scaleX;
// //             const scaledY1 = cropCoords.y1 * scaleY;
// //             const scaledX2 = cropCoords.x2 ? cropCoords.x2 * scaleX : scaledX1;
// //             const scaledY2 = cropCoords.y2 ? cropCoords.y2 * scaleY : scaledY1;
// //
// //             // Draw crop rectangle
// //             if (cropCoords.x2 !== null) {
// //                 ctx.strokeStyle = "white";
// //                 ctx.lineWidth = 2;
// //                 ctx.strokeRect(scaledX1, scaledY1, scaledX2 - scaledX1, scaledY2 - scaledY1);
// //
// //                 // Draw second selection point (red dot)
// //                 ctx.fillStyle = "red";
// //                 ctx.beginPath();
// //                 ctx.arc(scaledX2, scaledY2, 5, 0, Math.PI * 2);
// //                 ctx.fill();
// //             }
// //
// //             // Draw first selection point (blue dot)
// //             ctx.fillStyle = "blue";
// //             ctx.beginPath();
// //             ctx.arc(scaledX1, scaledY1, 5, 0, Math.PI * 2);
// //             ctx.fill();
// //         }
// //     }, [cropCoords]);
// //
// //     // Convert click coordinates to original image size
// //     const handle_canvas_click = (event) => {
// //         if (!imageRef.current || draggingPoint !== null) return;
// //
// //         const rect = canvasRef.current.getBoundingClientRect();
// //         const scaleX = imageRef.current.width / rect.width;
// //         const scaleY = imageRef.current.height / rect.height;
// //
// //         const x = (event.clientX - rect.left) * scaleX;
// //         const y = (event.clientY - rect.top) * scaleY;
// //
// //         if (cropCoords.x1 === null) {
// //             setCropCoords({ x1: x, y1: y, x2: null, y2: null });
// //         } else if (cropCoords.x2 === null) {
// //             setCropCoords({ ...cropCoords, x2: x, y2: y });
// //         }
// //     };
// //
// //     // Detect dragging on selection points
// //     const handle_mouse_down = (event) => {
// //         if (!imageRef.current) return;
// //         const rect = canvasRef.current.getBoundingClientRect();
// //         const scaleX = imageRef.current.width / rect.width;
// //         const scaleY = imageRef.current.height / rect.height;
// //
// //         const x = (event.clientX - rect.left) * scaleX;
// //         const y = (event.clientY - rect.top) * scaleY;
// //
// //         const threshold = 10 * scaleX;
// //
// //         if (cropCoords.x1 !== null && Math.abs(x - cropCoords.x1) < threshold && Math.abs(y - cropCoords.y1) < threshold) {
// //             setDraggingPoint("start");
// //         } else if (cropCoords.x2 !== null && Math.abs(x - cropCoords.x2) < threshold && Math.abs(y - cropCoords.y2) < threshold) {
// //             setDraggingPoint("end");
// //         }
// //     };
// //
// //     // Adjust crop coordinates when dragging
// //     const handle_mouse_move = (event) => {
// //         if (!imageRef.current || draggingPoint === null) return;
// //         const rect = canvasRef.current.getBoundingClientRect();
// //         const scaleX = imageRef.current.width / rect.width;
// //         const scaleY = imageRef.current.height / rect.height;
// //
// //         const x = (event.clientX - rect.left) * scaleX;
// //         const y = (event.clientY - rect.top) * scaleY;
// //
// //         if (draggingPoint === "start") {
// //             setCropCoords((prev) => ({ ...prev, x1: x, y1: y }));
// //         } else if (draggingPoint === "end") {
// //             setCropCoords((prev) => ({ ...prev, x2: x, y2: y }));
// //         }
// //     };
// //
// //     const handle_mouse_up = () => {
// //         setDraggingPoint(null);
// //     };
// //
// //     // Crop and upload image
// //     const crop_image = () => {
// //         if (!imageSrc || !canvasRef.current || cropCoords.x2 === null) return;
// //
// //         const canvas = document.createElement("canvas");
// //         const ctx = canvas.getContext("2d");
// //
// //         const img = imageRef.current;
// //         const { x1, y1, x2, y2 } = cropCoords;
// //         const width = x2 - x1;
// //         const height = y2 - y1;
// //
// //         canvas.width = width;
// //         canvas.height = height;
// //
// //         ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);
// //
// //         canvas.toBlob((blob) => on_crop_complete(blob), "image/jpeg");
// //     };
// //
// //     return (
// //         <div>
// //             <input type="file" accept="image/*" onChange={handle_image_upload} />
// //             <div
// //                 style={{
// //                     position: "relative",
// //                     display: imageSrc ? "block" : "none",
// //                     border: "1px solid black",
// //                     marginTop: "10px",
// //                 }}
// //                 onMouseDown={handle_mouse_down}
// //                 onMouseMove={handle_mouse_move}
// //                 onMouseUp={handle_mouse_up}
// //                 onClick={handle_canvas_click}
// //             >
// //                 <canvas ref={canvasRef} style={{ width: "100%" }} />
// //             </div>
// //             <button onClick={crop_image_client_side} disabled={cropCoords.x2 === null}>
// //                 Crop & Upload
// //             </button>
// //         </div>
// //     );
// // };
// //
// // export default ImageCropper;
// //
// //
// // import React, { useState, useRef, useEffect } from "react";
// // import LStyles from "../styles/library.module.css";
// //
// // const ImageCropper = ({ on_crop_complete }) => {
// //     const [images, setImages] = useState({ front: null, back: null, publication: null });
// //     const [cropCoords, setCropCoords] = useState({
// //         front: { x1: null, y1: null, x2: null, y2: null },
// //         back: { x1: null, y1: null, x2: null, y2: null },
// //         publication: { x1: null, y1: null, x2: null, y2: null }
// //     });
// //
// //     const [activeCanvas, setActiveCanvas] = useState("front");
// //     const [draggingOver, setDraggingOver] = useState(null);
// //     const canvasRefs = { front: useRef(null), back: useRef(null), publication: useRef(null) };
// //     const imageRefs = { front: useRef(new Image()), back: useRef(new Image()), publication: useRef(new Image()) };
// //
// //     // Prevent default drag behavior
// //     useEffect(() => {
// //         const preventDefaults = (e) => e.preventDefault();
// //         document.addEventListener("dragover", preventDefaults);
// //         document.addEventListener("drop", preventDefaults);
// //
// //         return () => {
// //             document.removeEventListener("dragover", preventDefaults);
// //             document.removeEventListener("drop", preventDefaults);
// //         };
// //     }, []);
// //
// //     // Handle file selection
// //     const handle_image_upload = (file, section) => {
// //         if (file && !images[section]) {
// //             const reader = new FileReader();
// //             reader.onload = () => {
// //                 setImages((prev) => ({ ...prev, [section]: reader.result }));
// //             };
// //             reader.readAsDataURL(file);
// //         }
// //     };
// //
// //     // Handle drag-and-drop
// //     const handle_drag_over = (event, section) => {
// //         event.preventDefault();
// //         setDraggingOver(section);
// //     };
// //
// //     const handle_drag_leave = () => {
// //         setDraggingOver(null);
// //     };
// //
// //     const handle_drop = (event, section) => {
// //         event.preventDefault();
// //         setDraggingOver(null);
// //         const file = event.dataTransfer.files[0];
// //         handle_image_upload(file, section);
// //     };
// //
// //     // Remove image and reset state
// //     const handle_remove_image = (section) => {
// //         setImages((prev) => ({ ...prev, [section]: null }));
// //         setCropCoords((prev) => ({ ...prev, [section]: { x1: null, y1: null, x2: null, y2: null } }));
// //     };
// //
// //     // Draw image when it loads
// //     useEffect(() => {
// //         if (!images[activeCanvas] || !canvasRefs[activeCanvas].current) return;
// //
// //         const canvas = canvasRefs[activeCanvas].current;
// //         const ctx = canvas.getContext("2d");
// //         const img = new Image();
// //         img.src = images[activeCanvas];
// //
// //         img.onload = () => {
// //             const maxWidth = 800;
// //             const scale = Math.min(maxWidth / img.width, 1);
// //             const newWidth = img.width * scale;
// //             const newHeight = img.height * scale;
// //
// //             canvas.width = newWidth;
// //             canvas.height = newHeight;
// //
// //             ctx.clearRect(0, 0, newWidth, newHeight);
// //             ctx.drawImage(img, 0, 0, newWidth, newHeight);
// //
// //             imageRefs[activeCanvas].current = img;
// //         };
// //     }, [images, activeCanvas]);
// //
// //     // Handle click to mark crop points
// //     const handle_canvas_click = (event) => {
// //         if (!imageRefs[activeCanvas].current) return;
// //
// //         const rect = canvasRefs[activeCanvas].current.getBoundingClientRect();
// //         const scaleX = imageRefs[activeCanvas].current.width / rect.width;
// //         const scaleY = imageRefs[activeCanvas].current.height / rect.height;
// //
// //         const x = (event.clientX - rect.left) * scaleX;
// //         const y = (event.clientY - rect.top) * scaleY;
// //
// //         setCropCoords((prev) => {
// //             const coords = prev[activeCanvas];
// //             if (coords.x1 === null) {
// //                 return { ...prev, [activeCanvas]: { x1: x, y1: y, x2: null, y2: null } };
// //             } else if (coords.x2 === null) {
// //                 return { ...prev, [activeCanvas]: { ...coords, x2: x, y2: y } };
// //             }
// //             return prev;
// //         });
// //     };
// //
// //     // Crop images before sending
// //     const submit_cropped_images = () => {
// //         const croppedImages = {};
// //
// //         Object.keys(images).forEach((section) => {
// //             if (!images[section] || cropCoords[section].x2 === null) return;
// //
// //             const canvas = document.createElement("canvas");
// //             const ctx = canvas.getContext("2d");
// //
// //             const img = imageRefs[section].current;
// //             const { x1, y1, x2, y2 } = cropCoords[section];
// //             const width = x2 - x1;
// //             const height = y2 - y1;
// //
// //             canvas.width = width;
// //             canvas.height = height;
// //
// //             ctx.drawImage(img, x1, y1, width, height, 0, 0, width, height);
// //
// //             canvas.toBlob((blob) => {
// //                 croppedImages[section] = blob;
// //                 if (Object.keys(croppedImages).length === Object.keys(images).length) {
// //                     on_crop_complete(croppedImages);
// //                 }
// //             }, "image/jpeg");
// //         });
// //     };
// //
// //     return (
// //         <div className={LStyles.cropper_container}>
// //             <h2>Upload Book Images</h2>
// //             {["front", "back", "publication"].map((section) => (
// //                 <div
// //                     key={section}
// //                     className={`${LStyles.upload_area} ${activeCanvas === section ? LStyles.active : ""} ${draggingOver === section ? LStyles.drag_over : ""}`}
// //                     onDragOver={(e) => handle_drag_over(e, section)}
// //                     onDragLeave={handle_drag_leave}
// //                     onDrop={(e) => handle_drop(e, section)}
// //                     onClick={() => setActiveCanvas(section)}
// //                 >
// //                     <label className={LStyles.upload_label}>
// //                         {section === "front" ? "Front Cover" : section === "back" ? "Back Cover" : "Publication Page"}
// //                         {!images[section] && (
// //                             <input type="file" accept="image/*" onChange={(e) => handle_image_upload(e.target.files[0], section)} hidden />
// //                         )}
// //                     </label>
// //                     {images[section] && (
// //                         <div className={LStyles.upload_controls}>
// //                             <span>✅ Image uploaded</span>
// //                             <button className={LStyles.remove_button} onClick={() => handle_remove_image(section)}>
// //                                 Remove
// //                             </button>
// //                         </div>
// //                     )}
// //                 </div>
// //             ))}
// //             <div className={LStyles.canvas_container}>
// //                 {images[activeCanvas] && (
// //                     <canvas ref={canvasRefs[activeCanvas]} className={LStyles.crop_canvas} onClick={handle_canvas_click} />
// //                 )}
// //             </div>
// //             <button onClick={submit_cropped_images} className={LStyles.submit_button}>
// //                 Submit Cropped Images
// //             </button>
// //         </div>
// //     );
// // };
// //
// // export default ImageCropper;
