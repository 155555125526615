import React, {useEffect, useState} from "react";
import Styles from "../styles/language.module.css";
import {talk} from "./LangUtils";

const LanguageLoginForm = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState("");

    //OTHER FUNCTIONS
    const block_form_submission = (event) => {
        event.preventDefault();

        process_login();
    }

    //AJAX FUNCTIONS
    const process_login = () => {
        talk(
            "Language_Handler.php",
            "PROCESS_LOGIN",
            {
                email: email,
                password:password
            },
            process_login_handler
        );
    }
    const process_login_handler = (operation, sent_data, return_data) => {
        if(return_data['status'] === 'success') {
            setLoginError("");
            props.handle_login(
                return_data['user_data']['email'],
                return_data['user_data']['user_id'],
                return_data['user_data']['firstname']
            );
        } else {
            setLoginError("Failed to login.");
        }
    }

    return (
        <div className={Styles.language_login_container}>
            <div className={Styles.language_login_title_row}>
                <h2>Login</h2>
            </div>

            <div className={Styles.language_login_form_container}>
                <form onSubmit={block_form_submission}>
                    <div className={Styles.language_login_row}>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            className={Styles.language_login_input}
                        />
                    </div>

                    <div className={Styles.language_login_row}>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            className={Styles.language_login_input}
                        />
                    </div>

                    <div className={Styles.language_login_row}>
                        <button type="submit" className={Styles.language_login_submit}>
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LanguageLoginForm;
