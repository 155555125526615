import React, {useEffect, useState} from 'react';
import { Outlet } from "react-router-dom";
import Styles from "../styles/language.module.css";
import LanguageLoginForm from "./language_login_form";
import {useUser} from "./UserContext";


const LanguageLayout = () => {
    // const [showLoginForm, setShowLoginForm] = useState(false);
    const {userId, login, firstName, logout, check_login_status, finishedLoggingIn} = useUser();
    const [showProfileHolder, setShowProfileHolder] = useState(false);

    useEffect(() => {
        console.log("LANG USER ID: " + userId);
    }, [userId]);
    useEffect(() => {
        check_login_status();
    }, []);

    const show_profile_holder = () => {
        setShowProfileHolder(!showProfileHolder);
    }
    const process_logout = () => {
        setShowProfileHolder(false);
        logout();
    }

    return (
        <>
            <div className={Styles.lang_nav_bar}>
                <a
                    href="#"
                    onClick={show_profile_holder}
                >👤</a>
                <a href="./">Home</a>
                <a href="writing-textbook">Writing Textbook</a>
            </div>

            <div className={showProfileHolder ? Styles.lang_profile_div : Styles.hidden}>
                {firstName ? <span className={Styles.firstname_span}>Welcome, {firstName}!</span> : ''}
                {userId ? (<a
                    href="#"
                    onClick={process_logout}
                    className={Styles.logout_link}
                >LOGOUT</a>) : ''}
            </div>

            <div className={Styles.main_body_div}>
                {!userId && finishedLoggingIn ? <LanguageLoginForm handle_login={login} /> : ''}
                <div>
                    <Outlet/>
                </div>
                <div>UPBUTTON</div>
            </div>
        </>
    );
};
export default LanguageLayout;


