import React, {useEffect, useState} from "react";
import {load_fetch, talk} from "./ofb_util";
import TabSelector from "./tab_selector";
import Styles from "./styles/ofbstyles.module.css";
import LibraryRatingSystem from "./Library/LibraryRatingSystem";
import BookShelf from "./Library/BookShelf";
import AddBookForm from "./Library/AddBookForm";
import LibraryContext from "./Library/LibraryContext";
import ImageCropper from "./Library/ImageCropper";
import AutoInsertBookForm from "./Library/AutoInsertBookForm";


const Library = (props) => {
    const [libraryData, setLibraryData] = useState(false);
    const [libraryOptions, setLibraryOptions] = useState(false);
    const [editTabs, setEditTabs] = useState([
        // {id: 1, name: "ABCDE", value: "ABCDE"},
        // {id: 2, name: "FGHJI", value: "FGHI"},
        // {id: 3, name: "KLMN", value: "KLMN"},
        // {id: 4, name: "OPQR", value: "OPQR"},
        // {id: 5, name: "ST", value: "ST"},
        // {id: 6, name: "UVWXYZ", value: "UVWXYZ"},
        // {id: 7, name: "ALL", value: "ALL"},
        {id: 8, name: "🔍", value: "SEARCH"},
        {id: 9, name: "+", value: "ADD_NEW"},
        {id: 10, name: "📖", value: "VIEW_READ_BOOKS"},
        {id: 11, name: "📚", value: "VIEW_OWNED_BOOKS"},
        {id: 12, name: "🖨️", value: "PRINT_LABELS"},
        {id: 13, name: "⏳", value: "SHOW_RECENT_ADDITIONS"},
        {id: 14, name: "📷", value: "AUTO_IMPORT_FROM_PHOTOS"}
    ]);
    const [filterParams, setFilterParams] = useState({lower: -1, upper: -1, special: ''});
    const [searchTerms, setSearchTerms] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const MemoizedRatingSystem = React.memo(LibraryRatingSystem);
    const [totalBooksRead, setTotalBooksRead] = useState(false);
    const [readThisYear, setReadThisYear] = useState(false);
    const years = new Date().getFullYear() - 2014;
    const [autoInsertBookFormData, setAutoInsertBookFormData] = useState(false);
    const [showImageCropper, setShowImageCropper] = useState(false);
    const [showRecentAdditions, setShowRecentAdditions] = useState(false);

    //USE EFFECTS
    useEffect(() => {
        if(!libraryData) {
            get_library_data();
        }
        if(!libraryOptions) {
            get_library_options();
        }
        if(!totalBooksRead) {
            get_total_books_read();
        }
    }, []);
    useEffect(() => {
        if(!libraryData) {
            get_library_data();
        }
        if(!libraryOptions) {
            get_library_options();
        }
    }, [props.authStatus]);

    //AJAX FUNCTIONS
    const get_library_data = () => {
        let url = 'Library_Handler.php';
        let postData = {
            operation: 'GET_ALL_BOOKS'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setLibraryData(data);
            } else {
                setLibraryData(false);
            }
        });
    }
    const get_library_options = () => {
        let url = 'Library_Handler.php';
        let postData = {
            operation: 'GET_LIBRARY_SOURCES'
        }
        load_fetch(url, postData, (data) => {
            setLibraryOptions({
                    sources: data['SOURCES'],
                    statuses: data['STATUSES'],
                    types: data['TYPES']
            });
        });
    }
    const get_total_books_read = () => {
        let url = 'Library_Handler.php';
        let postData = {
            operation: 'GET_TOTAL_BOOKS_READ'
        }
        load_fetch(url, postData, (data) => {
            setTotalBooksRead(data['TOTAL']);
            setReadThisYear(data['THISYEAR']);
        });
    }
    const sortTabHandler = (props) => {


        switch(props) {
            case "ADD_NEW":
                setShowAddNew(!showAddNew);
                setSearchTerms('');
                setShowSearch(false);
                break;
            case "SEARCH":
                if(showSearch) {
                    setSearchTerms('');
                }
                setShowSearch(!showSearch);
                setShowAddNew(false);
                break;
            case "VIEW_READ_BOOKS":
                setShowSearch(false);
                setShowAddNew(false);
                setFilterParams({lower:-2, upper: -2, special: "VIEW_READ_BOOKS"});
                break;
            case "VIEW_OWNED_BOOKS":
                setShowSearch(false);
                setShowAddNew(false);
                setFilterParams({lower:-2, upper: -2, special: "VIEW_OWNED_BOOKS"});
                break;
            case "PRINT_LABELS":
                document.location.href='./library-print-labels';
                break;
            case "ALL":
                setFilterParams({lower: -1, upper: -1, special: ''});
                break;
            case "AUTO_IMPORT_FROM_PHOTOS":
                setFilterParams({lower: -1, upper: -1, special: ''});
                setShowImageCropper(!showImageCropper);
                break;
            case "SHOW_RECENT_ADDITIONS":
                setFilterParams({lower: -1, upper: -1, special: ''});
                setShowRecentAdditions(!showRecentAdditions);
                break;
            default:
                setFilterParams({lower: props.toLowerCase().charCodeAt(0),
                    upper: props.toLowerCase().charCodeAt(props.length - 1),
                    special: filterParams['special']});
                break;
        }



        if(props === 'ALL') {

        } else {

        }
    }
    const upload_cropped_book_cover = async (cropped_blobs) => {
        if (!cropped_blobs) {
            console.error("No cropped image data available.");
            return;
        }

        let formData = new FormData();

        for(let key in cropped_blobs) {
            console.log("TEMP_" + key + ".jpg");
            formData.append(key, cropped_blobs[key], "TEMP_" + key + ".jpg");
        }
        try {
            talk(
                "Library_Handler.php",
                "UPLOAD_BOOK_PHOTOS_FOR_BIBLIOGRAPHICAL_EXTRACTION",
                formData,
                upload_cropped_book_cover_handler
            );
        } catch (error) {
            console.error("Error uploading cropped image:", error);
        }
    };
    const upload_cropped_book_cover_handler = (operation, sent_data, return_data) => {
        return_data.acquired = new Date().toISOString().slice(0, 19).replace("T", " ");
        return_data.type = "Print Book";
        return_data.source = "Personal Library";
        return_data.all_ocr_data = JSON.stringify(return_data.all_ocr_data);
        return_data.isbn = return_data.isbn.toString().replace(" ", "");
        setAutoInsertBookFormData(return_data);
    }
    const save_auto_insert_book = (data) => {
        talk(
            "Library_Handler.php",
            "AUTO_INSERT_BOOK",
            data,
            save_auto_insert_book_handler
        );
    }
    const save_auto_insert_book_handler = (operation, sent_data, return_data) => {
        if(!return_data['STATUS']) {
            alert("ERROR");
            console.log(return_data);
            return;
        }

        //Clear the form
        setAutoInsertBookFormData(false);

        //Reset the book images
        setShowImageCropper(false);
    }

    const flatten_json = (data, prefix = "") => {
        let flatObject = {};

        for (let key in data) {
            if (typeof data[key] === "object" && data[key] !== null) {
                if (Array.isArray(data[key])) {
                    // Convert array to comma-separated values
                    flatObject[`${prefix}${key}`] = data[key].join(", ");
                } else {
                    // Recursively flatten nested objects with key hierarchy
                    let nestedObject = flatten_json(data[key], `${prefix}${key}_`);
                    Object.assign(flatObject, nestedObject);
                }
            } else {
                // Directly assign simple key-value pairs
                flatObject[`${prefix}${key}`] = data[key];
            }
        }

        return flatObject;
    };




    //OTHER FUNCTIONS
    const SearchBox = () => {
        if(showSearch) {
            return (
                <input autoFocus={true} type='text' value={searchTerms} className={Styles.ofb_search_box}
                       onChange={e => setSearchTerms(e.target.value)}/>
            )
        } else {
            return <></>
        }
    }
    const cancel_add_new = () => {
        setShowAddNew(false);
    }

    //RENDERING
    if(props.authStatus) {
        return (
            <LibraryContext.Provider value={libraryOptions}>
                <div className={Styles.ofb_library_header}>
                    <MemoizedRatingSystem />
                    <div className={totalBooksRead ? Styles.ofb_library_stats : Styles.ofb_hide}>
                        <div><b>Total Books Read</b>: {totalBooksRead}</div>
                        <div><b>Average Per Year</b>: {Math.round(totalBooksRead/years)}</div>
                        <div><b>Read This Year</b>: {readThisYear}</div>
                    </div>
                </div>
                <TabSelector
                    clickHandler={sortTabHandler}
                    visible={true}
                    items={editTabs}
                />
                {showImageCropper ? <ImageCropper on_crop_complete={upload_cropped_book_cover} /> : ''}
                {autoInsertBookFormData ? <AutoInsertBookForm formData={autoInsertBookFormData} onSave={save_auto_insert_book} /> : ''}
                <SearchBox/>
                <AddBookForm
                    showAddNew={showAddNew}
                    setShowAddNew={setShowAddNew}
                    cancelAddNew={cancel_add_new}
                    authStatus={props.authStatus}
                    reloadFunction={get_library_data}
                />
                <div className={showAddNew ? Styles.ofb_hide : ''}>
                    <BookShelf
                        showRecentAdditions={showRecentAdditions}
                        libraryData={libraryData}
                        filterParams={filterParams}
                        searchTerms={searchTerms}
                        showSearch={showSearch}
                        reloadFunction={get_library_data}
                    />
                </div>
            </LibraryContext.Provider>
        )
    } else {
        return null;
    }
}

export default Library;