import TodoDailyItem from "./todo_daily_item";

const TabSelector = (props) => {

    function clearTabBackgroundColors() {
        let buttons = document.getElementsByClassName('tablink');
        for(let i = 0; i < buttons.length; i++) {
            buttons[i].className = buttons[i].className.replace('w3-black', 'w3-red');
        }
    }
    const localClickHandler = (sender, item) => {
        //Reset all button colors
        clearTabBackgroundColors();

        //Set the selected one to black
        sender.className = sender.className.replace('w3-red', 'w3-black');

        //Send the event on up to the parent
        props.clickHandler(item.value)
    }

    if(props.visible) {
        return (
            <div className="w3-bar">
                {props.items.map((item) =>
                    <button
                        key={item.id}
                        title={item.value}
                        className={`w3-bar-item w3-button tablink ${item.selected ? 'w3-black' : 'w3-red'}`}
                        onClick={event => localClickHandler(event.target, item)}
                    >
                        {item.name}
                    </button>
                )}
            </div>
        );
    } else {
        return <></>
    }

};

export default TabSelector;