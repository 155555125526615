import React, {useEffect, useRef, useState} from 'react';
import DetailRow from "./DetailRow";
import EditRow from "./EditRow";
import Styles from "../styles/ofbstyles.module.css";
import LStyles from "../styles/library.module.css";
import {talk} from "../ofb_util";

const BookShelf = (props) => {
    //DATA
    const [editingItem, setEditingItem] = useState(false);
    const [booksData, setBooksData] = useState([]);
    const [startingBookId, setStartingBookId] = useState(false);
    const [maxBooks, setMaxBooks] = useState(15);

    //USE EFFECTS
    useEffect(() => {
        //Load initial books
        load_books("current");
    }, [])
    useEffect(() => {
        if(props.showRecentAdditions) {
            load_recent_books();
        } else {
            setStartingBookId(false);
            load_books("current", false);
        }
    }, [props.showRecentAdditions])

    //AJAX FUNCTIONS
    const load_books = (direction, temp_starting_book_id = null) => {
        let postData = {
            max_books: maxBooks,
            starting_book_id: temp_starting_book_id ?? startingBookId,
            direction: direction
        }

        talk(
            "Library_Handler.php",
            "GET_SET_OF_BOOKS",
            postData,
            load_books_handler
        )
    }
    const load_books_handler = (operation, sent_data, return_data) => {
        if (return_data['ERROR']) {
            alert("ERROR - Failed to retrieve any books.");
            return;
        }

        setBooksData(return_data);
        setStartingBookId(return_data[0]['bookid']);
        console.log(return_data);
    }
    const load_recent_books = () => {
        talk(
            "Library_Handler.php",
            "LOAD_RECENT_BOOKS",
            null,
            load_recent_books_handler
        )
    }
    const load_recent_books_handler = (operation, sent_data, return_data) => {
        if (return_data['ERROR']) {
            alert("ERROR - Failed to retrieve any books.");
            return;
        }

        setBooksData(return_data);
        setStartingBookId(return_data[0]['bookid']);
        console.log(return_data);
    }

    const reload_parent_library_data = () => {
        props.reloadFunction();
    }
    const display_details = (props) => {
        if(editingItem) {
            return;
        }
        let el = document.getElementById('details_row_' + props);
        el.classList.toggle('w3-hide');
    }
    const get_rating_stars = (star_count) => {
        switch (star_count) {
            case 1:
                return <span className={Styles.ofb_library_rating_1}>* </span>
            case 2:
                return <span className={Styles.ofb_library_rating_2}>* * </span>
            case 3:
                return <span className={Styles.ofb_library_rating_3}>* * * </span>
            case 4:
                return <span className={Styles.ofb_library_rating_4}>* * * * </span>
            case 5:
                return <span className={Styles.ofb_library_rating_5}>* * * * * </span>
            default:
                return <></>
        }
    }
    const LibraryRow = (props) => {
        return (
            <>
                <tr
                    onClick={() => display_details(props['all_data']['bookid'])}
                    className={`${Styles.ofb_table_main_row} ${props['all_data']['status'] == 'WANT_TO_READ' ? Styles.ofb_library_want_to_read : ''}`}
                >
                    <td>
                        {props['all_data']['cover_thumbnail_image'] ?
                            <img className={LStyles.thumbnail} src={`./book_covers/${props['all_data']['bookid']}_front_cover_thumb.jpg`} />
                            : ''}
                    </td>
                    <td>{props['all_data']['authorlastname']}</td>
                    <td>{props['all_data']['authorfirstname']}</td>
                    <td>{props['all_data']['title']}</td>
                    <td className={Styles.ofb_library_rating_cell}>{get_rating_stars(props['all_data']['rating'])}</td>
                </tr>{editingItem === props['all_data']['bookid'] ? (
                    <EditRow
                        all_data={props['all_data']}
                        cancel_edit={handle_cancel_edit}
                        reloadFunction={reload_parent_library_data}
                    />
                ) : (
                <DetailRow
                    all_data={props['all_data']}
                    show_edit_form={show_edit_form}
                />
                )}
            </>
        )
    }
    const show_edit_form = (props) => {
        setEditingItem(props.bookid);
    }
    const handle_cancel_edit = () => {
        setEditingItem(false);
    }
    const get_pagination_controls = () => {
        return (
            <div className={LStyles.pagination_container}>
                <button onClick={() => load_books("previous")}>
                    Prev
                </button>
                <button onClick={() => load_books("next")}>
                    Next
                </button>

                {/*<button onClick={() => (currentPage.current = 1)} disabled={currentPage.current === 1}>*/}
                {/*    Start*/}
                {/*</button>*/}
                {/*<button onClick={() => currentPage.current = ((p) => Math.max(p - 1, 1))} disabled={currentPage.current === 1}>*/}
                {/*    Prev*/}
                {/*</button>*/}

                {/*/!* Editable Page Number *!/*/}
                {/*{isEditingPage ? (*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        value={pageInput}*/}
                {/*        onChange={handle_page_input_change}*/}
                {/*        onKeyDown={handle_page_input_submit}*/}
                {/*        onBlur={() => setIsEditingPage(false)}*/}
                {/*        className={LStyles.page_input}*/}
                {/*        autoFocus*/}
                {/*    />*/}
                {/*) : (*/}
                {/*    <span className={LStyles.page_number} onClick={() => { setIsEditingPage(true); setPageInput(currentPage.current); }}>*/}
                {/*                Page {currentPage.current} of {totalPages}*/}
                {/*            </span>*/}
                {/*)}*/}

                {/*<button onClick={() => currentPage.current = ((p) => Math.min(p + 1, totalPages))} disabled={currentPage.current === totalPages}>*/}
                {/*    Next*/}
                {/*</button>*/}
                {/*<button onClick={() => currentPage.current = (totalPages)} disabled={currentPage.current === totalPages}>*/}
                {/*    End*/}
                {/*</button>*/}
            </div>)
    }

    return (
        <div key="all_books_holder">
            {get_pagination_controls()}
        <table className={`${Styles.ofb_pointer} ${Styles.ofb_table}`}>
            <tbody>
            {
                booksData.length > 0 && booksData.map(item => (
                    <LibraryRow
                        key={item['bookid']}
                        all_data={item}
                    />
                ))}
            </tbody>
        </table>
            {get_pagination_controls()}
        </div>
    )

    // // Jump to a valid page
    // const jump_to_page = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //         currentPage.current = page;
    //     }
    // };
    //
    // // Handle input for the page number
    // const handle_page_input_change = (e) => {
    //     setPageInput(e.target.value);
    // };
    //
    // // Handle Enter key press
    // const handle_page_input_submit = (e) => {
    //     if (e.key === "Enter") {
    //         const newPage = parseInt(pageInput, 10);
    //         if (!isNaN(newPage)) {
    //             jump_to_page(newPage);
    //         }
    //         setIsEditingPage(false);
    //     }
    // };
    // const get_pagination_controls = () => {
    //     return (
    //         <div className={LStyles.pagination_container}>
    //             <button onClick={() => (currentPage.current = 1)} disabled={currentPage.current === 1}>
    //                 Start
    //             </button>
    //             <button onClick={() => currentPage.current = ((p) => Math.max(p - 1, 1))} disabled={currentPage.current === 1}>
    //                 Prev
    //             </button>
    //
    //             {/* Editable Page Number */}
    //             {isEditingPage ? (
    //                 <input
    //                     type="text"
    //                     value={pageInput}
    //                     onChange={handle_page_input_change}
    //                     onKeyDown={handle_page_input_submit}
    //                     onBlur={() => setIsEditingPage(false)}
    //                     className={LStyles.page_input}
    //                     autoFocus
    //                 />
    //             ) : (
    //                 <span className={LStyles.page_number} onClick={() => { setIsEditingPage(true); setPageInput(currentPage.current); }}>
    //                             Page {currentPage.current} of {totalPages}
    //                         </span>
    //             )}
    //
    //             <button onClick={() => currentPage.current = ((p) => Math.min(p + 1, totalPages))} disabled={currentPage.current === totalPages}>
    //                 Next
    //             </button>
    //             <button onClick={() => currentPage.current = (totalPages)} disabled={currentPage.current === totalPages}>
    //                 End
    //             </button>
    //         </div>)
    // }
    //
    //
    // function filter_books() {
    //     if (props.filterParams['lower'] === -1 && props.filterParams['upper'] === -1 && !props.searchTerms) {
    //         console.log("YO");
    //         console.log(props.libraryData);
    //         setFilteredBookData(props.libraryData);
    //     } else if (props.searchTerms && props.showSearch) {
    //         setFilteredBookData(props.libraryData.filter(book => {
    //             if (book['authorlastname'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
    //                 return true;
    //             } else if (book['authorfirstname'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
    //                 return true;
    //             } else if (book['title'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
    //                 return true;
    //             } else if (book['series'] && book['series'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
    //                 return true;
    //             }
    //             return false;
    //         }));
    //     } else {
    //         //TODO: integrate personal books/library books filter with the alphabet and search filter.
    //         // In other words, layer the two filters together
    //         setFilteredBookData(props.libraryData.filter(book => {
    //             if(props.filterParams['special'] === 'VIEW_OWNED_BOOKS') {
    //                 return book['source'] === 'Personal Library';
    //             } else if (props.filterParams['special'] === 'VIEW_READ_BOOKS') {
    //                 return book['source'] !== 'Personal Library';
    //             } else {
    //                 const author = book['authorlastname'].toLowerCase();
    //                 return (author.charCodeAt(0) >= props.filterParams['lower'] && author.charCodeAt(0) <= props.filterParams['upper']);
    //             }
    //         }))
    //     }
    //     currentPage.current = 1;
    // }

    // if (props.libraryData && !props.libraryData['ERROR']) {
    //     return (
    //         <div key="all_books_holder">
    //             {/* Pagination Controls */}
    //             {get_pagination_controls()}
    //
    //             <table className={`${Styles.ofb_pointer} ${Styles.ofb_table}`}>
    //                 <tbody>
    //                 {
    //                     currentBooks && currentBooks.map(item => (
    //                         <LibraryRow
    //                             key={item['bookid']}
    //                             all_data={item}
    //                         />
    //                     ))}
    //                 </tbody>
    //             </table>
    //
    //             {/* Pagination Controls */}
    //             {get_pagination_controls()}
    //         </div>
    //     )
    // } else {
    //     return <></>
    // }
}

export default BookShelf;