const TOCData = [
    {
        "title": "Introduction",
        "sections": [
            {
                "title": "Why Writing is Important",
                "url": "why-writing-is-important",
                "sections": []
            },
            {
                "title": "How to Use this Book",
                "sections": [
                    {
                        "title": "For Students"
                    },
                    {
                        "title": "For Instructors"
                    }
                ]
            },
            {
                "title": "Course Schedule",
                "url": "course-schedule",
                "sections": []
            }
        ]
    },
    {
        "title": "Beginnings - Getting to Know You",
        "sections": [
            {
                "title": "Review",
                "sections": [
                    {
                        "title": "The Alphabet",
                        "url": "alphabet"
                    }
                ]
            },
            {
                "title": "Paragraph Analysis",
                "sections": [
                    {
                        "title": "Paragraph Analysis - Jeff's Life",
                        "url": "chapter_01_jeff"
                    },
                    {
                        "title": "Paragraph Analysis - Maria's Life",
                        "url": "chapter_01_maria"
                    }
                ]
            },
            {
                "title": "Grammar Focus - The BE Verb",
                "sections": [
                    {
                        "title": "BE Verb - Present Tense",
                        "url": "chapter_01_be_verb_present_tense"
                    },
                    {
                        "title": "BE Verb - Negatives",
                        "url": "chapter_01_be_verb_negatives"
                    },
                    {
                        "title": "BE Verb - Questions",
                        "url": "chapter_01_be_verb_questions"
                    }
                ]
            }
        ]
    },
    {
        "title": "Writing about Daily Life",
        "sections": [
            {
                "title": "Final Thoughts",
                "sections": []
            },
            {
                "title": "Next Steps",
                "sections": []
            }
        ]
    },
    {
        "title": "Hobbies and Interests (Expanding Sentences)",
        "sections": [
            {
                "title": "Final Thoughts",
                "sections": []
            },
            {
                "title": "Next Steps",
                "sections": []
            }
        ]
    },
    {
        "title": "Favorite Moments (Complex Sentences)"
    },
    {
        "title": "Social Media (Writing Paragraphs)"
    },
    {
        "title": "Health & Wellness (Midterm Review)"
    },
    {
        "title": "Around the World (Writing Paragraphs II)"
    },
    {
        "title": "Problem Solving (Correcting Common Errors)"
    },
    {
        "title": "Food & Recipes (Process Writing)"
    },
    {
        "title": "Comparing Cultures (Comparison Writing)"
    },
    {
        "title": "Hot Topics (Persuasive Writing)"
    },
    {
        "title": "Dreams & Goals (Cause/Effect Writing)"
    },
    {
        "title": "Teamwork (The Revision Process)"
    },
    {
        "title": "Reflection (Final Review)"
    }
]

export default TOCData;