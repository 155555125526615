import PageHeader from "./page_header";
import styles from './styles/language.module.css';
import { Link } from 'react-router-dom';
import IntroductionToLinguistics from "./introduction-to-linguistics";

const Language = (props) => {
    return (
        <>
            <PageHeader page_title="OUT FROM BABEL"/>

            <div style={{textAlign: "left"}}>
                <h3>Linguistics Text</h3>
                <p className={styles.language_sub_item}><strong>In Progress</strong>: <Link
                        to='../introduction-to-linguistics'
                        className={styles.language_link}
                    >
                        Open Content Introduction to Linguistics Textbook
                    </Link>
                    </p>
                <h3>Irregular Past Tense Verbs Drill</h3>
                <p className={styles.language_sub_item}><strong>Large Set (74 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 1 (12 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=1'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=1&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 2 (10 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=2'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=2&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 3 (12 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=3'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=3&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 4 (10 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=4'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=4&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 5 (11 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=5'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=5&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 6 (10 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=2'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=6&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Small Set 7 (9 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=7'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=7&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <p className={styles.language_sub_item}><strong>Giant Set (343 verbs total):</strong> <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=giant'
                    className={styles.language_link}
                >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/irregular_past_tense_level_one/index.php?g=giant&printworksheet=1'
                    className={styles.language_link}
                >Printable Worksheet</a>
                </p>

                <h3>Regular Plural Spelling</h3>
                <p className={styles.language_sub_item}><strong>Set 1 (41 verbs):</strong> <a
                    href='https://outfrombabel.com/esl-files/regular_plural_spelling/'
                    className={styles.language_link}
                    >Flashcards</a> - <a
                    href='https://outfrombabel.com/esl-files/regular_plural_spelling/?printworksheet=1'
                    className={styles.language_link}
                    >Printable Worksheet</a>
                </p>

                <h3>ESL Writing Textbook</h3>
                <p className={styles.language_sub_item}><strong>A1/A2</strong> <a
                    href='https://outfrombabel.com/writing-textbook'
                    className={styles.language_link}
                >
                    Online Textbook</a>
                </p>
            </div>
        </>
    );
};

export default Language;