import React, { useState } from "react";
import Styles from "./styles/quiz.module.css";

const QuizModule = ({ quizData, isLoggedIn, userName }) => {
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [attempts, setAttempts] = useState(0);
    const [isPrintView, setIsPrintView] = useState(false);
    const [tempName, setTempName] = useState("");
    const [flashColor, setFlashColor] = useState("");
    const [isRandomize, setIsRandomize] = useState(false);

    const currentQuiz = quizData[currentIndex];
    const { question, answer } = currentQuiz;
    const parts = question.split("%%");

    const handleSubmit = () => {
        setAttempts(attempts + 1);
        if (currentAnswer.trim().toLowerCase() === answer.toLowerCase()) {
            setScore(score + 1);
            setFlashColor("green");
            setTimeout(() => {
                setFlashColor(""); // Reset flashColor first
                setCurrentAnswer(""); // Clear the input
                if (isRandomize) {
                    const randomIndex = Math.floor(Math.random() * quizData.length);
                    setCurrentIndex(randomIndex);
                } else if (currentIndex + 1 < quizData.length) {
                    setCurrentIndex(currentIndex + 1);
                } else {
                    alert("Quiz completed!");
                }
            }, 1000); // Delay to match the flash color duration
        } else {
            setFlashColor("red");
            setTimeout(() => setFlashColor(""), 1000); // Only reset flashColor on incorrect answers
        }
    };


    const handlePrint = () => {
        if (isLoggedIn || tempName) {
            setIsPrintView(true);
        } else {
            alert("Please enter your name to print your scores.");
        }
    };

    const handlePrintConfirm = () => {
        if (!isLoggedIn) setTempName(tempName.trim());
        window.print();
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
        }
    };

    if (isPrintView) {
        return (
            <div className={Styles.printView}>
                <h1>Quiz Results</h1>
                <p><strong>Name:</strong> {isLoggedIn ? userName : tempName}</p>
                <p><strong>Date:</strong> {new Date().toLocaleDateString()}</p>
                <p><strong>Score:</strong> {score}/{attempts}</p>
                <button onClick={() => setIsPrintView(false)}>Back to Quiz</button>
            </div>
        );
    }

    return (
        <div
            className={Styles.quizContainer}
            style={{ backgroundColor: flashColor ? flashColor : "" }}
        >
            <div className={Styles.statsContainer}>
                <div className={Styles.top_row_left}></div>
                <div className={Styles.stats}>
                    {flashColor ? (
                        flashColor === 'green' ? <div className={Styles.correct}>CORRECT!</div> :
                            <div className={Styles.incorrect}>INCORRECT!</div>
                    ) : <div>Score: {score}/{attempts}</div>}
                </div>
                <div className={Styles.randomizeContainer}>
                    <label>
                        <input
                            type="checkbox"
                            checked={isRandomize}
                            onChange={() => setIsRandomize(!isRandomize)}
                        /> Randomize
                    </label>
                </div>
            </div>

            <div className={Styles.quizBox}>
                <p className={Styles.quizSentence}>
                    {parts[0]} <input type="text" value={currentAnswer} className={Styles.quiz_input} onChange={(e) => setCurrentAnswer(e.target.value)} onKeyDown={handleKeyDown} /> {parts[1]}
                </p>
            </div>

            <button onClick={handleSubmit} className={Styles.submitButton}>Submit</button>

            {(!isLoggedIn && !tempName) && (
                <div className={Styles.nameBox}>
                    <label>
                        Enter your name:
                        <input type="text" className={Styles.quiz_input} value={tempName} onChange={(e) => setTempName(e.target.value)} />
                    </label>
                </div>
            )}

            <button onClick={handlePrint} className={Styles.printButton}>Print Scores</button>
        </div>
    );
};

export default QuizModule;
