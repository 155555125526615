import React, { useState } from 'react';
import FStyles from '../styles/finance.module.css';

const MonthYearSelector = (props) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
        props.date_change(e.target.value, selectedYear);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        props.date_change(selectedMonth, e.target.value);
    };

    return (
        <div className={FStyles.month_year_selector_container}>
            <label className={FStyles.selector_label}>
                Month:
                <select
                    className={FStyles.selector}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                >
                    {[...Array(12).keys()].map((index) => (
                        <option key={index} value={index + 1}>
                            {new Date(0, index).toLocaleString('default', { month: 'long' })}
                        </option>
                    ))}
                </select>
            </label>
            <label className={FStyles.selector_label}>
                Year:
                <select
                    className={FStyles.selector}
                    value={selectedYear}
                    onChange={handleYearChange}
                >
                    {[2020, 2021, 2022, 2023, 2024, 2025].map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
};

export default MonthYearSelector;
