export function talk(url, operation, post_data, handler) {
    let postData = {
        operation: operation,
        ...post_data
    }
    load_fetch(url, postData, (data) => {
        try {
            let json_data = JSON.parse(data);
            handler(operation, post_data, json_data);
        } catch (e) {
            console.log(e);
            console.log(data);
        }
    },'text');
}
export function load_fetch(relative_url, postData, callback, textOrJson='json') {
    let url = "../ofb_api/" + relative_url;
    //console.log("In load_fetch(): " + url);

    const options = {
        method: 'POST',
        body: JSON.stringify(postData),
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    if(textOrJson === 'json') {
        fetch(url, options)
            .then(response => {
                if(!response.ok) {
                    throw new Error("Failed with HTTP code " + response.status);
                }
                return response;
            })
            .then(result => result.json())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    } else {
        fetch(url, options)
            .then(result => result.text())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    }
}