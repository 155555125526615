import React from "react";
import Styles from "./textbook_styles.module.css";

const CourseOverview = () => {

    return (
        <div className={Styles.course_overview_table_holder}>
        <table className={Styles.course_overview_table}>
            <colgroup>
                <col width="39"/>
                <col width="213"/>
                <col width="213"/>
                <col width="213"/>
                <col width="213"/>
                <col width="213"/>
            </colgroup>
            <tbody>
            <tr className={Styles.course_overview_table_header}>
                <td>Wk.</td>
                <td>THEME</td>
                <td>Level 1 Writing Outcomes</td>
                <td>Level 1 Grammar Skills</td>
                <td>Level 2 Writing Outcomes</td>
                <td>Level 2 Grammar Skills</td>
            </tr>
            <tr>
                <td>1</td>
                <td>GETTING TO KNOW YOU</td>
                <td>Course introduction; review of the alphabet, punctuation, and spelling basics; introduction to
                    simple sentences
                </td>
                <td>Simple Present (including 3rd person singular -s); "Be" verb in simple tense</td>
                <td>Course introduction; review of spelling patterns; introduction to elaborating simple sentences</td>
                <td>Simple Present (3rd person singular -s); "Be" verb in simple tense</td>
            </tr>
            <tr>
                <td>2</td>
                <td>DAILY LIFE</td>
                <td>Writing simple sentences on familiar topics (e.g., personal information)</td>
                <td>Plurals (basic spelling rules and common irregular), articles</td>
                <td>Writing detailed simple sentences with descriptive elements (e.g., adjectives, adverbs)</td>
                <td>Plurals (basic spelling rules and common irregular), articles</td>
            </tr>
            <tr>
                <td>3</td>
                <td>FRIENDSHIP</td>
                <td>Introduction to compound sentences; combining ideas with "and," "but," "or"</td>
                <td>Simple Past (regular and approximately 25 irregular verbs); "Be" verb in simple past tense</td>
                <td>Using compound sentences with more complex conjunctions (e.g., "yet," "so")</td>
                <td>Simple Past (regular and approximately 50 irregular verbs); basic present perfect</td>
            </tr>
            <tr>
                <td>4</td>
                <td>HOBBIES AND INTERESTS</td>
                <td>Writing 3-5 sentences on familiar topics (e.g., daily routines, likes/dislikes)</td>
                <td>Adjectives (including comparatives and superlatives); prepositions of place (under, next to, etc.);
                    present progressive verbs
                </td>
                <td>Writing 6-8 sentence unified paragraphs on personal topics (e.g., hobbies, routines)</td>
                <td>Adjectives (including comparatives and superlatives); prepositions of time and place; present and
                    past progressive verbs
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>FAVORITE MOMENTS</td>
                <td>Introduction to complex sentences with basic subordinators (e.g., "because," "when")</td>
                <td>Simple and compound sentences; Coordinating conjunctions ("and," "but," "or")</td>
                <td>Complex sentences with a variety of subordinators (e.g., "although," "since," "while")</td>
                <td>Review of simple and compound sentences; Coordinating conjunctions ("and," "but," "or," "so," "yet,"
                    "for," "nor"); tag questions
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>SOCIAL MEDIA</td>
                <td>Writing short messages (e.g., social media posts, simple emails)</td>
                <td>Basic complex sentences; Subordinating conjunctions ("because," "when," "if", etc)</td>
                <td>Writing emails and short paragraphs with cohesive transitions and elaboration</td>
                <td>Complex sentences; using sentence variety; Subordinating conjunctions (all types)</td>
            </tr>
            <tr>
                <td>7</td>
                <td>HEALTH AND WELLNESS</td>
                <td>Midterm review: Sentence types, common errors, spelling patterns</td>
                <td>Pronoun-antecedent agreement; subject-verb agreement; determiners, and quantifiers</td>
                <td>Midterm review: Sentence variety, drafting paragraphs, applying feedback</td>
                <td>Pronoun-antecedent agreement; subject-verb agreement; determiners, and quantifiers</td>
            </tr>
            <tr>
                <td>8</td>
                <td>PLACES AROUND THE WORLD</td>
                <td>Writing 6-8 unified sentences with a model (e.g., describing a place, giving directions)</td>
                <td>Simple Future (will); introduction to conjunctions for sequence</td>
                <td>Writing 8-10 sentence paragraphs in narrative and descriptive styles</td>
                <td>Simple Future (will); introduction to "be going to"; sequence conjunctions</td>
            </tr>
            <tr>
                <td>9</td>
                <td>PROBLEM-SOLVING</td>
                <td>Correcting fragments and basic spelling errors</td>
                <td>Modals: can, may (requests, offers, ability); negative present tense statements</td>
                <td>Correcting run-on sentences, comma splices, and spelling errors</td>
                <td>Modals: can/could, may/might, should/ought to, have to/must, negative present tense statements</td>
            </tr>
            <tr>
                <td>10</td>
                <td>FOOD AND RECIPES</td>
                <td>Writing short notes and simple instructions (e.g., recipes, daily tasks)</td>
                <td>Interrogative pronouns (who, what, where, etc.); common sentence errors (fragments, subject-verb
                    agreement)
                </td>
                <td>Writing process paragraphs with clear steps and appropriate formatting</td>
                <td>Interrogative pronouns (expanded use); common sentence errors (run-ons, comma splices, fragments)
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>COMPARING CULTURES</td>
                <td>Expanding sentence variety and vocabulary through guided exercises</td>
                <td>Frequency adverbs (always, sometimes, never); prepositions of time (in, on, at)</td>
                <td>Writing comparison/contrast paragraphs with transitions (e.g., "however," "similarly")</td>
                <td>Frequency adverbs (often, seldom); prepositional phrases of time and place</td>
            </tr>
            <tr>
                <td>12</td>
                <td>PERSUASIVE TOPICS</td>
                <td>Writing sentences using common transition words (e.g., "and then," "after that")</td>
                <td>Demonstratives (this, that, these, those); possessives (nouns and pronouns); -ly adverbs for
                    manner
                </td>
                <td>Writing persuasive paragraphs with strong topic sentences and supporting details</td>
                <td>Demonstrative pronouns (this, that, these, those); possessive adjectives; reflexive pronouns; -ly
                    adverbs (expanded use)
                </td>
            </tr>
            <tr>
                <td>13</td>
                <td>DREAMS AND GOALS</td>
                <td>Writing 6-10 unified sentences on a familiar topic (e.g., a holiday, a favorite activity)</td>
                <td>Review of modals (can, may, should), conjunctions (and, but, or)</td>
                <td>Writing cause/effect paragraphs using complex sentence structures and transitions</td>
                <td>Review of modals (expanded list); conjunctions (coordinating and subordinating)</td>
            </tr>
            <tr>
                <td>14</td>
                <td>TEAMWORK</td>
                <td>Peer and self-review of sentences and short compositions</td>
                <td>Comprehensive review</td>
                <td>Drafting, revising, and editing paragraphs with peer and teacher feedback</td>
                <td>Comprehensive review</td>
            </tr>
            <tr>
                <td>15</td>
                <td>REFLECTION</td>
                <td>Final review: Sentence variety, spelling, corrections</td>
                <td></td>
                <td>Final review: Paragraph types, corrections, applying feedback effectively</td>
                <td></td>
            </tr>
            </tbody>
        </table>
        </div>
    );
}

export default CourseOverview;