import FStyles from "../styles/finance.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import React, {useEffect, useState} from "react";
import {format_currency, talk} from "../ofb_util";
import DateInput from "./DateInput";
import Styles from "../styles/ofbstyles.module.css";
import TrashCan from "../images/trash_can.png";
import {clear} from "@testing-library/user-event/dist/clear";
import AreYouSureModal from "../are_you_sure_modal";

//TODO: Using Max_records and Offset greatly increases loading speed, but it messes with balance
// - How long to load all records, do the math on the server side, but only send back X number of transactions?
// - This even would be a temporary fix
// - Still takes 4 seconds... need to find a way to fix it (perhaps, like a bank... since last statement, and then by previous statements

/**
 * This component must receive an account_name in the props
 * @param props     Must include an account_name and cancel_transaction function
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleEntry = (props) => {
    //STATE DATA
    const [addingNewPayee, setAddingNewPayee] = useState(false);
    const [addingNewCategory, setAddingNewCategory] = useState(false);
    const [today, setToday] = useState(new Date().toISOString().split('T')[0]);
    const [splits, setSplits] = useState([]);
    const [payees, setPayees] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedPayee, setSelectedPayee] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [isIncome, setIsIncome] = useState(false); //false for expense, true for income
    const [selectedStatus, setSelectedStatus] = useState('Unreconciled');
    const [checkNumber, setCheckNumber] = useState('');
    const [transactionTotal, setTransactionTotal] = useState("0.00");
    const [showTransactionDataPreview, setShowTransactionDataPreview] = useState(false);
    const [mostRecentTransactionDate, setMostRecentTransactionDate] = useState(props.mostRecentDate);


    //USE EFFECTS
    useEffect(() => {
        if(splits.length === 0) {
            add_empty_split_to_data();
        }
        get_payees();
        get_categories();
    }, []);
    useEffect(() => {
        //console.log(splits);
    }, [splits])
    useEffect(() => {
        if(props.edit_transaction) {
            console.log("LOADING TRANSACTION DATA TO EDIT:");
            console.log(props.edit_transaction);

            if(props.edit_transaction['transaction_type_id'] === 2) {
                setIsIncome(true);
            } else {
                setIsIncome(false);
            }
            setSelectedDate(convert_date(props.edit_transaction['transaction_date']));
            setSelectedPayee(props.edit_transaction['payee_name']);
            switch(props.edit_transaction['reconciliation_status']) {
                case "C":
                    setSelectedStatus("Cleared");
                    break;
                case "R":
                    setSelectedStatus("Reconciled");
                    break;
                default:
                    setSelectedStatus("Not Reconciled");
                    break;
            }
            setCheckNumber(props.edit_transaction['number']);

            //Assemble Splits
            let splits = [];
            for(let i = 0; i < props.edit_transaction['transaction_parts'].length; i++) {
                let tpart = props.edit_transaction['transaction_parts'][i];
                let split = {
                    account_id: tpart['account_id'],
                    account_name: tpart['account_name'],
                    transaction_part_id: tpart['transaction_part_id'],
                    category_id: tpart['category_id'] ? tpart['category_id'] : tpart['transfer_account_id'],
                    category: tpart['category_name'] ? tpart['category_name'] : tpart['transfer_account_name'],
                    new_category: false,
                    amount: convert_cents_to_dollars(tpart['amount'], props.edit_transaction['transaction_type_id']),
                    memo: tpart['memo']
                }
                splits.push(split);
            }
            setSplits(splits);
            recalculate_transaction_total(splits);
        }
    }, [props.edit_transaction]);

    //AJAX FUNCTIONS
    const get_payees = () => {
        talk("Finance_Handler.php", "GET_PAYEES", null, get_payees_handler);
    }
    const get_payees_handler = (operation, send_data, return_data) => {
        setPayees(return_data);
    }
    const get_categories = () => {
        talk("Finance_Handler.php", "GET_CATEGORIES", null, get_categories_handler);
    }
    const get_categories_handler = (operation, sent_data, return_data) => {
        setCategories(return_data);
    }
    const save_transaction = () => {
        //Assemble the Data for Posting
        let postData = create_post_data();

        //Perform Validation of the Data
        if(postData['transaction_date'].length !== 10) {
            alert("You must enter a date for the transaction.");
            return;
        }
        if(postData.payee.length <= 0) {
            alert("You must select or enter a payee.");
            return;
        }
        //Validate the splits
        for(let i = 0; i < splits.length; i++) {
            //Check for a Category
            if(splits[i]['category'].length <= 0) {
                alert("You must enter a category for every transaction part.");
                return;
            }

            //Check for a reasonable amount
            if(isNaN(parseFloat(splits[i]['amount']))) {
                alert(splits[i]['amount'] + " is not a valid amount.");
                return;
            }
        }

        //Send the Data
        talk("Finance_Handler.php", "INSERT_NEW_TRANSACTION", postData, save_transaction_handler);
    }
    const save_transaction_handler = (operation, sent_data, return_data) => {
        if(return_data && !return_data['ERROR']) {
            //Clear the form
            reset_form();

            //Update the Most Recent Transaction Date
            setMostRecentTransactionDate(sent_data['transaction_date']);

            //Sent up to the Ledger that the transaction has been completed and saved
            props.save_transaction_handler();
        } else {
            console.log(return_data);
        }
    }
    const save_edit_transaction = () => {
        console.log(splits);
        let t = props.edit_transaction;

        t['transaction_type'] = isIncome ? "deposit" : "withdrawal";
        t['transaction_date'] = selectedDate;
        t['reconciliation_status'] = selectedStatus;
        t['payee_name'] = selectedPayee;

        t['transaction_parts'] = splits;

        // for(let i = 0; i < t['transaction_parts'].length; i++) {
        //     t['transaction_parts'][0]['amount'] *= 100;
        // }

        let postData = {
            transaction: t
        }

        talk(
            "Finance_Handler.php",
            "SAVE_EDIT_TRANSACTION",
            postData,
            save_edit_transaction_handler);
    }
    const save_edit_transaction_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }


    //OTHER FUNCTIONS
    // const focus_on_date = () => {
    //     document.getElementById('date_input').focus();
    // }
    const cancel_add_transaction = () => {
        //reset form
        reset_form();

        //call parent cancel
        props.cancel_transaction();
    }
    const cancel_edit_transaction = () => {
        //reset form
        reset_form();

        //call parent cancel
        props.cancel_edit_transaction();
    }
    const create_post_data = () => {
        return {
            transaction_date: selectedDate,
            type: isIncome ? "deposit" : "withdrawal",
            payee: selectedPayee,
            memo: null,
            number: checkNumber,
            account: props.account_name,
            reconciliation_status: selectedStatus,
            transaction_parts: splits
        }
    }
    const preview_transaction_data = () => {
        if(!showTransactionDataPreview) {
            document.getElementById('preview_div').textContent =
                JSON.stringify(create_post_data(), null, 5);
        }
        setShowTransactionDataPreview(!showTransactionDataPreview);
    }
    const remove_split = (index) => {
        if(splits.length <= 1) {
            return;
        }
        let local_data = JSON.parse(JSON.stringify(splits));
        local_data.splice(index, 1);
        setSplits(local_data);
        recalculate_transaction_total(local_data);
    }
    const update_split_data = (field, split_array_index, value) => {
        //Create a local copy of the data
        let local_data = JSON.parse(JSON.stringify(splits));

        //Perform the edit
        local_data[split_array_index][field] = value;

        //Update the State
        setSplits(local_data);

        //If amount is changing, update the transaction total
        if(field === 'amount') {
            recalculate_transaction_total(local_data);
        }
    }
    const recalculate_transaction_total = (local_data) => {
        let total = 0;
        for(let i = 0; i < local_data.length; i++) {
            let split_amount = parseFloat(local_data[i]['amount']);
            if(isNaN(split_amount)) {
                setTransactionTotal("--");
                return;
            }
            total += (split_amount) * 100;
        }
        setTransactionTotal(total / 100);
    }
    const toggle_new_category = (index) => {
        //Create a local copy of the data
        let local_data = JSON.parse(JSON.stringify(splits));

        //Perform the edit
        local_data[index]['category'] = '';
        local_data[index]['new_category'] = !local_data[index]['new_category'];

        //Update the State
        setSplits(local_data);
    }
    const toggle_new_payee = () => {
        setSelectedPayee('');
        setAddingNewPayee(!addingNewPayee);
    }
    const generate_split_row = (item_data, index) => {
        return (
            <div className={FStyles.simple_entry_split}>
                <div className={FStyles.simple_entry_row}>
                    <div className={FStyles.simple_entry_label_div}>Split {index + 1}</div>
                    <div className={(item_data['new_category']) ? GenStyles.hidden : ''}>
                        <select
                            onChange={(event) =>
                                update_split_data('category', index, event.target.value)}
                            value={item_data['category']}
                        >
                            <option></option>
                            {categories.length > 0 && categories.map((item, index) => (
                                <option key={'CATEGORIES_' + index}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className={(item_data['new_category']) ? '' : GenStyles.hidden}>
                        <input
                            className={FStyles.simple_entry_row_general_input}
                            type="text"
                            size="30"
                            value={item_data['category']}
                            onChange={(event) =>
                                update_split_data('category', index, event.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            className={FStyles.new_category_button}
                            onClick={() => toggle_new_category(index)}
                            title={item_data['new_category'] ? 'Cancel New Category' : 'Add New Category'}
                        >{item_data['new_category'] ? 'x' : '+'}</button>
                    </div>
                </div>
                <div className={FStyles.simple_entry_row}>
                    <div className={FStyles.simple_entry_label_div}></div>
                    <div>
                        <input
                            type="text"
                            size="10"
                            placeholder="$"
                            className={FStyles.simple_entry_amount_input}
                            value={splits[index].amount}
                            onChange={(event) =>
                                update_split_data('amount', index, event.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            size="10"
                            placeholder="memo"
                            className={FStyles.simple_entry_memo_input}
                            value={item_data['memo']}
                            onChange={(event) =>
                                update_split_data('memo', index, event.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            className={FStyles.delete_split_button}
                            onClick={() => remove_split(index)}
                            title="Delete Split"
                        ><img src={TrashCan} className={FStyles.delete_split_button_image}/>️</button>
                    </div>
                </div>
            </div>
        );
    }
    const add_empty_split_to_data = () => {
        let new_split = {
            category_id: -1,
            category: "",
            new_category: false,
            amount: "0.00",
            memo: ""
        }
        setSplits([...splits, new_split]);
    }
    const handle_date_change = (newDate) => {
        setSelectedDate(newDate);
    }
    const reset_form = () => {
        setSelectedDate('');
        setIsIncome(false);
        setSelectedPayee('');
        setSelectedStatus('Not Reconciled');
        setCheckNumber('');
        setTransactionTotal("0");
        setShowTransactionDataPreview('');

        //Clear Splits
        setSplits([{
            category_id: -1,
            category: "",
            new_category: false,
            amount: "0.00",
            memo: "memo"
        }]);
    }
    const convert_date = (date_string) => {
        let [month, day, year] = date_string.split('/');
        const fullYear = year.length === 2 ? '20' + year : year; // Assuming 21st century
        day = day.padStart(2, '0');
        month = month.padStart(2, '0');
        return `${fullYear}-${month}-${day}`;
    }
    const convert_cents_to_dollars = (cents, transaction_type_id) => {
        if(transaction_type_id === 1) {
            //Flip the Sign
            cents = -(cents);
        }
        return (cents / 100);
    }

    return (
        <div>
            <div className={props.edit_transaction ? FStyles.simple_entry_row : GenStyles.hidden}>
                <h3>Editing Transaction</h3>
            </div>
            <div className={FStyles.income_expense_slider_row}>
                <div className={FStyles.simple_entry_label_div}>Expense</div>
                <label className={FStyles.switch}>
                    <input
                        type="checkbox"
                        onChange={(event) => setIsIncome(event.target.checked)}
                        checked={isIncome}
                    />
                    <span className={`${FStyles.slider} ${FStyles.round}`}></span>
                </label>
                <div>Income</div>
            </div>
            <div className={FStyles.simple_entry_row}>
                <div className={FStyles.simple_entry_label_div}>Date</div>
                <div className={FStyles.simple_entry_date_holder_div}>
                    <DateInput
                        name='transaction_date'
                        placeholder='transaction_date'
                        mostRecentDate={mostRecentTransactionDate ?? today}
                        startValue={mostRecentTransactionDate ?? today}
                        transaction_date={selectedDate ?? ''}
                        onDateChange={handle_date_change}
                        className={Styles.ofb_datetime_input}
                    />
                </div>
            </div>
            <div className={FStyles.simple_entry_row}>
                <div className={FStyles.simple_entry_label_div}>Payee</div>
                <div className={(addingNewPayee) ? GenStyles.hidden : ''}>
                    <select
                        onChange={(event) =>
                            setSelectedPayee(event.target.value)}
                        value={selectedPayee}
                    >
                        <option></option>
                        {payees.length > 0 && payees.map((item, index) => (
                            <option
                                key={'PAYEES_' + index}
                            >{item}</option>
                        ))}
                    </select>
                </div>
                <div className={(addingNewPayee) ? '' : GenStyles.hidden}>
                    <input
                        className={FStyles.simple_entry_row_general_input}
                        type="text"
                        size="30"
                        value={selectedPayee}
                        onChange={(event) =>
                            setSelectedPayee(event.target.value)}
                    />
                </div>
                <div>
                    <button
                        className={FStyles.simple_entry_mod_button}
                        onClick={() => toggle_new_payee()}
                    >{addingNewPayee ? 'Cancel' : 'New'}</button>
                </div>
            </div>
            <div className={FStyles.simple_entry_row}>
                <div className={FStyles.simple_entry_label_div}>Status</div>
                <div>
                    <select
                        value={selectedStatus}
                        onChange={(event) =>
                            setSelectedStatus(event.target.value)}
                    >
                        <option>Not Reconciled</option>
                        <option>Cleared</option>
                        <option>Reconciled</option>
                    </select>
                </div>
            </div>
            <div className={FStyles.simple_entry_row}>
                <div className={FStyles.simple_entry_label_div}>Check Number</div>
                <div>
                    <input
                        className={FStyles.simple_entry_row_general_input}
                        type="text"
                        size="30"
                        value={checkNumber}
                        onChange={(event) => setCheckNumber(event.target.value)}
                    />
                </div>
            </div>

            {splits.length > 0 && splits.map((item, index) => (
                <div key={'SPLIT_ROW_' + index}>{generate_split_row(item, index)}</div>
            ))}

            <div className={FStyles.simple_entry_row}>
                <div className={FStyles.simple_entry_label_div}></div>
                <div className={FStyles.simple_entry_transaction_total}>
                    TOTAL: ${transactionTotal}
                </div>
            </div>

            <div className={FStyles.simple_entry_row}>
                <div className={FStyles.simple_entry_label_div}></div>
                <div className={FStyles.simple_entry_centered_buttons_cell}>
                    <button
                        className={props.edit_transaction ? GenStyles.hidden : FStyles.simple_entry_button}
                        onClick={save_transaction}
                    >
                        Save Transaction
                    </button>
                    <button
                        className={FStyles.simple_entry_button}
                        onClick={add_empty_split_to_data}
                    >Add Split
                    </button>
                    <button
                        className={FStyles.simple_entry_button}
                        onClick={preview_transaction_data}
                    >
                        Preview Data
                    </button>
                    <button
                        className={props.edit_transaction ? GenStyles.hidden : FStyles.simple_entry_button}
                        onClick={cancel_add_transaction}
                    >
                        Cancel Transaction
                    </button>
                    <button
                        className={props.edit_transaction ? FStyles.simple_entry_button : GenStyles.hidden }
                        onClick={cancel_edit_transaction}
                    >
                        Cancel Edit
                    </button>
                    <button
                        className={props.edit_transaction ? FStyles.simple_entry_button : GenStyles.hidden }
                        onClick={save_edit_transaction}
                    >
                        Save Edit
                    </button>
                </div>
            </div>
            <div
                id="preview_div"
                className={showTransactionDataPreview ? FStyles.simple_entry_preview_div : GenStyles.hidden}
            ></div>
        </div>
    )
}

export default SimpleEntry;