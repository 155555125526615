import React, { useState } from "react";
import Styles from "../styles/language.module.css";

const TableOfContents = ({toc_data, handle_select}) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const generate_section = (section_info, lead_in, index, depth) => {
        return (
            <>
                <span className={get_subitem_style(depth)}><span className={section_info['url'] ? Styles.toc_clickable_item : ''}>{lead_in}{(index + 1)}{lead_in.length === 0 ? "." : ""} {section_info['title']}</span></span>
                <ul>
                {section_info['sections'] && section_info['sections'].map((item, sub_index) => (
                    <li
                        key={sub_index}
                        onClick={item['url'] ? (event) => load_section(event, item['url']) : ''}
                    >
                        {generate_section(item, lead_in + (index + 1) + '.', sub_index, depth + 1)}
                    </li>
                ))}
                </ul>
            </>
        )
    }
    const get_subitem_style = (depth) => {
        switch(depth) {
            case 0:
                return Styles.chapter;
            case 1:
                return Styles.subChapter;
            case 2:
                return Styles.subSubChapter;
            default:
                return '';
        }
    }
    const load_section = (event, url) => {
        event.stopPropagation();
        setIsVisible(false);
        handle_select(url);
    }

    return (
        <div className={Styles.tocContainer}>
            <button onClick={toggleVisibility} className={Styles.toggleButton}>
                {isVisible ? "Hide Table of Contents" : "Show Table of Contents"}
            </button>

            {isVisible && (
                <div className={Styles.tocContent}>
                    <h2 className={Styles.tocHeading}>Table of Contents</h2>
                    <ul className={Styles.tocList}>
                        {toc_data && toc_data.map((item, index) => (
                            <li key={index}>{generate_section(item, '', index, 0)}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TableOfContents;
