import React, { useState } from "react";
import LStyles from "../styles/library.module.css";

const AutoInsertBookForm = ({ formData, onSave }) => {
    // Initialize state with formData
    const [formState, setFormState] = useState(formData);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSaveData = (e) => {
        e.preventDefault(); // Prevent form reload
        onSave(formState); // Pass updated data to the parent
    };

    return (
        <div className={LStyles.form_container}>
            <h2 className={LStyles.form_title}>Auto-Entry Form</h2>
            <form className={LStyles.styled_form} onSubmit={handleSaveData}>
                {Object.entries(formState).map(([key, value]) => {
                    if (key.startsWith("other_data_")) return null; // Ignore "other_data_" keys

                    return (
                        <div key={key} className={LStyles.form_group}>
                            <label className={LStyles.form_label}>{key.replace(/_/g, " ")}:</label>
                            <input
                                type="text"
                                name={key}
                                className={LStyles.form_input}
                                value={value}
                                onChange={handleChange}
                            />
                        </div>
                    );
                })}
                <textarea
                    className={LStyles.all_ocr_data}
                    name="all_ocr_data"
                    value={formState.all_ocr_data}
                    onChange={handleChange}
                ></textarea>
                <button type="submit" className={LStyles.form_button}>
                    Save to Database
                </button>
            </form>
        </div>
    );
};

export default AutoInsertBookForm;
