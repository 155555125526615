import React, {useEffect, useState, useContext } from "react";
import TodoDailyGroup from "./daily_group";
import {AuthContext} from "../auth_context";
import {talk, load_fetch} from "../ofb_util";
import PageTitle from "../page_title";
import TabSelector from "../tab_selector";
import TodoDailyEditBlock from "./edit_block";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import TodayStyles from "../styles/today.module.css";


const Today = (props) => {
    const [error, setError] = useState(null);
    const [specificDate, setSpecificDate] = useState(
        (new Date()).getFullYear() + "-" + ((new Date()).getMonth() + 1).toString().padStart(2, "0") +
        "-" + (new Date()).getDate().toString().padStart(2, "0")
        );
    const [isLoaded, setIsLoaded] = useState(false);
    const [dueTodaySelectedItem, setDueTodaySelectedItem] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [items, setItems] = useState([]);
    const [todoDueTodayItems, setTodoDueTodayItems] = useState([]);
    const [authStatus, setAuthStatus] = useContext(AuthContext);
    const [isEditing, setIsEditing] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [editTabShown, setEditTabShown] = useState((new Date().toLocaleString("default",
        {weekday: "long"})).toUpperCase());
    const [editTabs, setEditTabs] = useState([
        {id: 1, name: "Sun", value: "SUNDAY"},
        {id: 2, name: "Mon", value: "MONDAY"},
        {id: 3, name: "Tues", value: "TUESDAY"},
        {id: 4, name: "Wed", value: "WEDNESDAY"},
        {id: 5, name: "Thu", value: "THURSDAY"},
        {id: 6, name: "Fri", value: "FRIDAY"},
        {id: 7, name: "Sat", value: "SATURDAY"},
        {id: 8, name: "Monthly", value: "MONTHLY"},
        {id: 9, name: "End of Month", value: "END_OF_MONTH"},
        {id: 10, name: "Orphans", value: "ORPHANS"},
        {id: 11, name: "+", value: "ADD_NEW"}
    ]);
    const [doneItems, setDoneItems] = useState([]);
    const [waivedItems, setWaivedItems] = useState([]);
    const [failedItems, setFailedItems] = useState([]);
    const [showDailyActionsModal, setShowDailyActionsModal] = useState(false);
    const [showDueTodayActionsModal, setShowDueTodayActionsModal] = useState(false);

    const handleDeleteItem = (dailyid) => {
        const postData = {
            operation: 'DELETE_ITEM',
            dailyid: dailyid,
        }
        const myCallback = (data) => {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO DELETE ITEM");
                console.log(data);
            } else if (data) {
                //console.log(data);
                loadItems();
            } else {
                console.log("Should return data, but isn't. In handleDeleteItem() in today_daily.js");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    //TODO: JUNE - Figure this out and use load_fetch for signin.js

    const editToggleEventHandler = () => {
        setIsEditing(!isEditing);
    }
    const setDateHandler = (props) => {
        setSpecificDate(props);
    }

    const dailyItemClickHandler = (info) => {
        setSelectedItem(info);
        show_daily_actions_modal();
    }
    const due_today_item_click_handler = (info) => {
        console.log(info);
        setDueTodaySelectedItem(info);
        show_due_today_actions_modal();
    }
    const change_daily_item_status = (id, new_status) => {
        const postData = {
            operation: new_status,
            id: id,
            specific_date: specificDate
        }
        const myCallback = (data) => {
            if(data && "error" in data) {
                console.log("ERROR: FAILED TO MARK ITEM DONE");
            } else if (data) {
                console.log(data)
                setItems(data['NOT_DONE']);
                setDoneItems(data['DONE']);
                setWaivedItems(data['WAIVED']);
                setFailedItems(data['FAILED']);
                loadItems();
            } else {
                console.log("RETURNED UNDEFINED");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    const mark_item_done = () => {
        change_daily_item_status(selectedItem['id'], 'MARK_ITEM_DONE');
        setSelectedItem(false);
        hide_daily_actions_modal();
    }
    const mark_todo_item_done = (todoid) => {
        //Do server side check for subitems and prevent completion.

        //Mark Item Completed
        const postData = {
            operation: "MARK_ITEM_COMPLETED",
            todoid: todoid
        }

        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                if(data && "ERROR" in data) {
                    console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
                    console.log(data);
                } else if (data) {
                    get_todo_due_today();
                    hide_due_today_actions_modal();
                } else {
                    console.log(data);
                }
            });
    }
    const move_todo_item_to_tomorrow = (todoid) => {
        //MOVE ITEM TO TOMORROW
        const postData = {
            operation: "MOVE_ITEM_TO_TOMORROW",
            todoid: todoid
        }
        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                hide_due_today_actions_modal();
                get_todo_due_today();
            });
    }
    const mark_item_waived = () => {
        change_daily_item_status(selectedItem['id'], 'MARK_ITEM_WAIVED');
        setSelectedItem(false);
        hide_daily_actions_modal();
    }
    const mark_item_failed = () => {
        change_daily_item_status(selectedItem['id'], 'MARK_ITEM_FAILED');
        setSelectedItem(false);
        hide_daily_actions_modal();
    }

    //When Editing, set the selected tab
    useEffect(() => {
        for(let i = 0; i < editTabs.length; i++) {
            if(editTabs[i].value == editTabShown) {
                editTabs[i].selected = true;
            }
        }
        loadItems();
    }, [editTabShown]);

    useEffect(() => {
        loadItems();
        get_todo_due_today();
    }, [isEditing, specificDate])


    const editTabHandler = (props) => {
        if(props == "ADD_NEW") {
            setShowAddNew(!showAddNew);
            return;
        }
        setEditTabShown(props);
    }
    const handleAddNew = (props) => {
        const postData = {
            operation: 'ADD_NEW_ITEM',
            itemData: props
        }

        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                //Check for a notification of success?
                //console.log(response)
                loadItems();
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    const get_todo_due_today = () => {
        console.log("Getting TODO Today Items for date: " + specificDate);
        const postData = {
            operation: 'GET_TODO_DUE_TODAY',
            specific_date: specificDate
        }

        // const myCallback = (response) => {
        //     console.log(response);
        //     if(response && "error" in response) {
        //         console.log("ERROR: " + response['error']);
        //     } else if (response) {
        //         console.log("TODO TODAY:");
        //         console.log(response);
        //         //Check for a notification of success?
        //         //console.log(response)
        //         setTodoDueTodayItems(response);
        //     } else {
        //         setTodoDueTodayItems([]);
        //         console.log("NO RESPONSE");
        //     }
        // }
        // load_fetch('todo_handler.php', postData, myCallback);

        talk('todo_handler.php',
            'GET_TODO_DUE_TODAY',
            postData,
            get_todo_due_today_handler);
    }
    const get_todo_due_today_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data && "error" in return_data) {
            console.log("ERROR: " + return_data['error']);
        } else if (return_data) {
            console.log("TODO TODAY:");
            console.log(return_data);
            //Check for a notification of success?
            //console.log(response)
            setTodoDueTodayItems(return_data);
        } else {
            setTodoDueTodayItems([]);
            console.log("NO RESPONSE");
        }
    }



    //Editing: Load proper tab group
    function loadItems() {
        console.log("loading items for: " + specificDate);
        //if(!logged In)

        let postData = {};

        if(isEditing) {
            switch (editTabShown) {
                case "SUNDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'SUNDAY';
                    break;
                case "MONDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'MONDAY';
                    break;
                case "TUESDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'TUESDAY';
                    break;
                case "WEDNESDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'WEDNESDAY';
                    break;
                case "THURSDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'THURSDAY';
                    break;
                case "FRIDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'FRIDAY';
                    break;
                case "SATURDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'SATURDAY';
                    break;
                case "MONTHLY":
                    postData['operation'] = 'GET_MONTHLY_ITEMS';
                    break;
                case "END_OF_MONTH":
                    postData['operation'] = 'GET_END_OF_MONTH_ITEMS';
                    break;
                case "ORPHANS":
                    postData['operation'] = 'ORPHANS';
                    break;
                default:
                    return;
            }
        } else {
            postData['operation'] = 'GET_ITEMS_FOR_TODAY';
            postData['editing'] = false;
            postData['specific_date'] = specificDate ?? null
        }

        talk(
            'daily_handler.php',
            postData['operation'],
            postData,
            load_today_items_handler
        );

        // const myCallback = (data) => {
        //     console.log(data);
        //     if(data && "error" in data) {
        //         setIsLoaded(true);
        //         setError(data['error']);
        //     } else if (data) {
        //         if(isEditing) {
        //             setIsLoaded(true);
        //             setItems(data);
        //         } else {
        //             setIsLoaded(true);
        //             setItems(data['NOT_DONE']);
        //             setDoneItems(data['DONE']);
        //             setWaivedItems(data['WAIVED']);
        //             setFailedItems(data['FAILED']);
        //         }
        //     } else {
        //         console.log("RETURNED UNDEFINED");
        //     }
        // }
        // load_fetch('daily_handler.php', postData, myCallback);
    }
    const load_today_items_handler = (operation, sent_data, return_data) => {
        if(!return_data) {
            setIsLoaded(true);
        } else {
            if(isEditing) {
                setIsLoaded(true);
                setItems(return_data);
            } else {
                setIsLoaded(true);
                setItems(return_data['NOT_DONE']);
                setDoneItems(return_data['DONE']);
                setWaivedItems(return_data['WAIVED']);
                setFailedItems(return_data['FAILED']);
            }
        }
        console.log(return_data);
    }

    const hide_daily_actions_modal = () => {
        setShowDailyActionsModal(false);
    }
    const hide_due_today_actions_modal = () => {
        setShowDueTodayActionsModal(false);
    }
    const show_daily_actions_modal = () => {
        setShowDailyActionsModal(true);
    }
    const show_due_today_actions_modal = () => {
        setShowDueTodayActionsModal(true);
    }
    const navigate_to_todo = () => {
        document.location.href="./todo-active-simple";
    }
    const navigate_to_overdue = () => {
        document.location.href="./todo-manage-overdue";
    }




    if (error) {
        return <div>{error}</div>;
    } else if (!isLoaded) {
        return (
            <>
                <PageTitle
                    toggleClickEventHandler={editToggleEventHandler}
                    setDateHandler={setDateHandler}
                />
                <div>Loading...</div>
            </>
        );
    } else if (authStatus) {
        return (
            <>
                <PageTitle
                    toggleClickEventHandler={editToggleEventHandler}
                    setDateHandler={setDateHandler}
                    specificDate={specificDate}
                />
                <TabSelector
                    clickHandler={editTabHandler}
                    visible={isEditing}
                    items={editTabs}
                />
                <TodoDailyEditBlock
                    type="addNew"
                    editingDetailsVisible={showAddNew}
                    handleSave={handleAddNew}
                    reloadItemsCall={loadItems}
                />
                <div>
                    <button className={`${GenStyles.red} ${GenStyles.button} ${GenStyles.font_white}`} onClick={navigate_to_todo}>TODO</button>
                    <button className={`${GenStyles.red} ${GenStyles.button} ${GenStyles.font_white}`} onClick={navigate_to_overdue}>Overdue items</button>
                </div>
                <div>
                    <h3 className={`${GenStyles.black} ${GenStyles.font_white}`}>TODO Items Due Today</h3>
                    <div style={{textAlign:"left"}}>
                    {todoDueTodayItems.length > 0 && todoDueTodayItems.map(item => (
                        <div key={item['todoid']}>{
                            item['completed'] ? (<div className={GenStyles.strike}>
                                {item.title}
                            </div>) : (<button className={(item['urgency'] !== 1 ? GenStyles.button :
                                `${GenStyles.red} ${GenStyles.button}`)}
                                               onClick={() => due_today_item_click_handler(item)}
                            >{item['title']}</button>)
                        }</div>
                    ))}
                    </div>
                </div>
                {items && items.map(group => (
                    <TodoDailyGroup
                        handleDeleteItem={handleDeleteItem}
                        key={group.timeOfDay}
                        editing={isEditing}
                        clickEventHandler={dailyItemClickHandler}
                        group={group}
                    /> ))
                }
                <div id="success_report">
                    <div>
                        <h3>Done</h3>
                        {doneItems && doneItems.map((item, index) => (
                            <div key={'done_item_' + index}>
                                {item['title']}
                            </div>
                        ))}
                    </div>
                    <div>
                        <h3>Waived</h3>
                        {waivedItems && waivedItems.map((item, index) => (
                            <div key={'waived_item_' + index}>
                                {item['title']}
                            </div>
                        ))}
                    </div>
                    <div>
                        <h3>Failed</h3>
                        {failedItems && failedItems.map((item, index) => (
                            <div key={'failed_item_' + index}>
                                {item['title']}
                            </div>
                        ))}
                    </div>
                </div>
                <div id="due_today_actions_modal" className={showDueTodayActionsModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
                            <span
                                onClick={hide_due_today_actions_modal}
                                className={`${GenStyles.button} ${GenStyles.display_top_right}`}
                            >&times;</span>
                            <h3 id="show_more_header_date">Due Today Item Actions</h3>
                        </header>

                        <div
                            className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
                            id="modal_actions_holder"
                        >
                            <div className={TodayStyles.actions_title}>
                                {dueTodaySelectedItem ? dueTodaySelectedItem['title'] : ""}
                            </div>
                            <button
                                className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                                onClick={() => mark_todo_item_done(dueTodaySelectedItem['todoid'])}
                            >
                                Completed
                            </button>
                            <button
                                className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_waived}`}
                                onClick={() => document.location.href='todo'}
                            >
                                View in TODO
                            </button>
                            <button
                                className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_failed}`}
                                onClick={() => move_todo_item_to_tomorrow(dueTodaySelectedItem['todoid'])}
                            >
                                Move to Tomorrow
                            </button>
                        </div>

                        <footer
                            className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}
                            id="modal_footer"
                        >
                            &nbsp;
                        </footer>
                    </div>
                </div>
                <div id="daily_actions_modal" className={showDailyActionsModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
                            <span
                                onClick={hide_daily_actions_modal}
                                className={`${GenStyles.button} ${GenStyles.display_top_right}`}
                            >&times;</span>
                            <h3 id="show_more_header_date">Daily Item Actions</h3>
                        </header>

                        <div
                            className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
                            id="modal_actions_holder"
                        >
                            <div className={TodayStyles.actions_title}>
                                {selectedItem ? selectedItem['title'] : ""}
                            </div>
                            <button
                                className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                                onClick={mark_item_done}
                            >
                                Completed
                            </button>
                            <button
                                className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_waived}`}
                                onClick={mark_item_waived}
                            >
                                Waived
                            </button>
                            <button
                                className={`${GenStyles.button} ${TodayStyles.actions_button}  
                                ${TodayStyles.actions_failed}`}
                                onClick={mark_item_failed}
                            >
                                Failed
                            </button>
                        </div>
                        <footer className={`${GenStyles.container} ${GenStyles.red}`} id="modal_footer">
                            &nbsp;
                        </footer>
                    </div>
                </div>
            </>
        );
    } else {
        return <></>
    }

};

export default Today;