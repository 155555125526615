import React, { useEffect, useRef } from 'react';

class LineGraph {
    constructor(canvasId, actualData, budgetedData) {
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext('2d');
        this.actualData = actualData;
        this.budgetedData = budgetedData;
        this.margin = 50;
        this.graphWidth = this.canvas.width - 2 * this.margin;
        this.graphHeight = this.canvas.height - 2 * this.margin;
        this.setupCumulativeData(); // Setup cumulative data first
        this.maxExpense = Math.max(
            ...this.actualCumulativeData.map(d => d.cumulativeExpense),
            ...this.budgetedCumulativeData.map(d => d.cumulativeExpense)
        ); // Now calculate the max expense
    }

    setupCumulativeData() {
        let actualCumulativeTotal = 0;
        this.actualCumulativeData = this.actualData.map((entry) => {
            actualCumulativeTotal += entry.amount;
            return { day: entry.day, cumulativeExpense: actualCumulativeTotal };
        });

        let budgetedCumulativeTotal = 0;
        this.budgetedCumulativeData = this.budgetedData.map((entry) => {
            budgetedCumulativeTotal += entry.amount;
            return { day: entry.day, cumulativeExpense: budgetedCumulativeTotal };
        });
    }

    scaleX(day) {
        return this.margin + (day - 1) * (this.graphWidth / 31);
    }

    scaleY(expense) {
        return this.canvas.height - this.margin - (expense / this.maxExpense) * this.graphHeight;
    }

    drawLine(data, switchAtDay, color) {
        this.ctx.beginPath();
        this.ctx.moveTo(this.scaleX(data[0].day), this.scaleY(data[0].cumulativeExpense));

        data.forEach((point, index) => {
            if (index === switchAtDay) {
                this.ctx.setLineDash([5, 5]); // Set dashed line pattern
            }
            this.ctx.lineTo(this.scaleX(point.day), this.scaleY(point.cumulativeExpense));
        });

        this.ctx.strokeStyle = color; // Color for line
        this.ctx.lineWidth = 2;
        this.ctx.stroke();
        this.ctx.setLineDash([]); // Reset to solid line after
    }

    drawAxes() {
        this.ctx.beginPath();
        this.ctx.moveTo(this.margin, this.margin);
        this.ctx.lineTo(this.margin, this.canvas.height - this.margin);
        this.ctx.lineTo(this.canvas.width - this.margin, this.canvas.height - this.margin);
        this.ctx.strokeStyle = 'black';
        this.ctx.stroke();
    }

    drawAxisLabels() {
        this.ctx.font = '12px Arial';
        this.ctx.fillStyle = 'black';
        this.actualCumulativeData.forEach((point, index) => {
            if (index % 5 === 0) {
                this.ctx.fillText(point.day, this.scaleX(point.day) - 10, this.canvas.height - this.margin + 20);
            }
        });

        this.ctx.textAlign = 'right';
        const yLabelStep = this.maxExpense / 5;
        for (let i = 0; i <= 5; i++) {
            const yValue = this.maxExpense - i * yLabelStep;
            this.ctx.fillText('$' + yValue.toFixed(0), this.margin - 10, this.scaleY(yValue) + 5);
        }
    }

    render() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.drawLine(this.actualCumulativeData, -1, 'black'); // Solid until day 14, then dashed
        this.drawLine(this.budgetedCumulativeData, 14, 'red'); // Solid throughout

        this.drawAxes();
        this.drawAxisLabels();
    }
}


const ExpenseGraph = ({ actualData, budgetedData }) => {
    const canvasRef = useRef(null);

    useEffect(() => {

        const lineGraph = new LineGraph(canvasRef.current.id, actualData, budgetedData);
        lineGraph.render();
    }, [actualData, budgetedData]); // Redraw if data changes

    return <canvas ref={canvasRef} id="expenseGraph" width={600} height={400}></canvas>;
};

export default ExpenseGraph;
