import React from "react";
import Styles from "./textbook_styles.module.css";
import AlphabetGame from "./alphabet_game";

const Alphabet = () => {
    return (
        <div className={Styles.container}>
            <h1 className={Styles.main_heading}>The English Alphabet</h1>

            <p className={Styles.paragraph}>
                The English alphabet is the foundation for reading and writing. It has 26 letters: uppercase (big
                letters) and lowercase (small letters).
            </p>

            <h2 className={Styles.sub_heading}>Uppercase and Lowercase Letters</h2>
            <p className={Styles.paragraph}>
                Each letter in English has two forms: uppercase and lowercase.
            </p>
            <p className={Styles.paragraph}>
                Here are all the letters of the alphabet:
            </p>
            <ul className={Styles.list}>
                <li>A a</li>
                <li>B b</li>
                <li>C c</li>
                <li>D d</li>
                <li>E e</li>
                <li>F f</li>
                <li>G g</li>
                <li>H h</li>
                <li>I i</li>
                <li>J j</li>
                <li>K k</li>
                <li>L l</li>
                <li>M m</li>
                <li>N n</li>
                <li>O o</li>
                <li>P p</li>
                <li>Q q</li>
                <li>R r</li>
                <li>S s</li>
                <li>T t</li>
                <li>U u</li>
                <li>V v</li>
                <li>W w</li>
                <li>X x</li>
                <li>Y y</li>
                <li>Z z</li>
            </ul>

            <h2 className={Styles.section_heading}>Consonants and Vowels</h2>
            <p className={Styles.paragraph}>
                The English alphabet has two types of letters:
            </p>
            <ul className={Styles.list}>
                <li><strong>Vowels:</strong> A, E, I, O, U</li>
                <li><strong>Consonants:</strong> All the other letters (like B, C, D, F, etc.)</li>
            </ul>

            <p className={Styles.paragraph}>
                Vowels are <strong>important letters</strong> in the English alphabet. You need vowels to make almost
                every word.
            </p>

            <h2 className={Styles.section_heading}>The Vowel Letters</h2>
            <p className={Styles.paragraph}>
                There are <strong>5 vowels</strong> in English:
            </p>
            <ul className={Styles.list}>
                <li><strong>A</strong></li>
                <li><strong>E</strong></li>
                <li><strong>I</strong></li>
                <li><strong>O</strong></li>
                <li><strong>U</strong></li>
            </ul>
            <p className={Styles.paragraph}>
                You can write them as:
            </p>
            <ul className={Styles.list}>
                <li><strong>Uppercase:</strong> A, E, I, O, U</li>
                <li><strong>Lowercase:</strong> a, e, i, o, u</li>
            </ul>

            <h2 className={Styles.section_heading}>Why Are Vowels Important?</h2>
            <p className={Styles.paragraph}>
                Every word needs at least <strong>one vowel</strong>. For example:
            </p>
            <ul className={Styles.list}>
                <li><strong>cat</strong> → The vowel is <strong>a</strong></li>
                <li><strong>pen</strong> → The vowel is <strong>e</strong></li>
                <li><strong>sun</strong> → The vowel is <strong>u</strong></li>
            </ul>

            <h2 className={Styles.section_heading}>Where Do We See Vowels?</h2>
            <p className={Styles.paragraph}>
                Vowels can be at the <strong>start</strong> of a word:
            </p>
            <ul className={Styles.list}>
                <li><strong>A</strong>pple</li>
                <li><strong>O</strong>range</li>
                <li><strong>U</strong>mbrella</li>
            </ul>
            <p className={Styles.paragraph}>
                Vowels can be in the <strong>middle</strong> of a word:
            </p>
            <ul className={Styles.list}>
                <li>H<strong>a</strong>t</li>
                <li>P<strong>e</strong>n</li>
                <li>S<strong>i</strong>t</li>
            </ul>
            <p className={Styles.paragraph}>
                Vowels can also be at the <strong>end</strong> of a word:
            </p>
            <ul className={Styles.list}>
                <li>Go</li>
                <li>He</li>
                <li>I</li>
            </ul>

            <AlphabetGame/>

            <h2 className={Styles.sub_heading}>Videos to Help You</h2>
            <p className={Styles.paragraph}>
                Watch these videos to learn how to say and write the letters of the alphabet.
            </p>

            <p className={Styles.paragraph}>
                <strong>Video 1:</strong> How to say the names of the capital letters.
            </p>
            <div className={Styles.videoPlaceholder}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Bz3Q1kqDRw4?si=M_p25bzRO9BBRBDI"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>

            <p className={Styles.paragraph}>
                <strong>Video 2:</strong> Writing uppercase and lowercase letters.
            </p>
            <div className={Styles.videoPlaceholder}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ExZiADS3a5s?si=LHa1Zp63iSdd7zya"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>

            <h2 className={Styles.sub_heading}>Practice Writing the Alphabet</h2>
            <p className={Styles.paragraph}>
                Practice writing each letter in uppercase and lowercase. Start with A and go to Z.
                Ask your instructor or a friend to check your work.
            </p>

        </div>
    );
};

export default Alphabet;