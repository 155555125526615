import React, { useState, useEffect } from "react";
import Styles from "./textbook_styles.module.css";
import GameStyles from "../game.module.css";

const AlphabetGame = () => {
    const vowels = ["A", "E", "I", "O", "U"];
    const consonants = "BCDFGHJKLMNPQRSTVWXYZ".split("");
    const [letters, setLetters] = useState([]);
    const [flashColor, setFlashColor] = useState(""); // For flashing green/red
    const [score, setScore] = useState(0);
    const [attempts, setAttempts] = useState(0);

    useEffect(() => {
        const randomSubset = getRandomLetters();
        setLetters(randomSubset);
    }, []);

    const getRandomLetters = () => {
        const shuffledConsonants = [...consonants].sort(() => 0.5 - Math.random());
        const randomConsonants = shuffledConsonants.slice(0, 10);
        return [...vowels, ...randomConsonants].sort(() => 0.5 - Math.random());
    };

    const handleDragStart = (e, letter) => {
        e.dataTransfer.setData("text/plain", letter);
    };

    const handleDrop = (e, boxType) => {
        const draggedLetter = e.dataTransfer.getData("text/plain");
        const isVowel = vowels.includes(draggedLetter);

        const correct =
            (boxType === "vowel" && isVowel) || (boxType === "consonant" && !isVowel);

        setFlashColor(correct ? "green" : "red");
        setTimeout(() => setFlashColor(""), 1000);

        if (correct) {
            setScore((prev) => prev + 1);
            setLetters((prevLetters) =>
                prevLetters.filter((letter) => letter !== draggedLetter)
            );
        }
        setAttempts((prev) => prev + 1);
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };
    const reset_game = () => {
        setLetters(getRandomLetters()); // Choose a new random subset of letters
        setScore(0);                    // Reset score to 0
        setAttempts(0);                 // Reset attempts to 0
        setFlashColor("");              // Clear any background flashes
    };


    return (
        <div
            className={`${GameStyles.alphabet_game_container}`}
            style={{ backgroundColor: flashColor }}
        >
            <h1 className={Styles.main_heading}>Sort the Letters</h1>
            <p className={Styles.paragraph}>
                Drag the letters into the correct box. Vowels go in the **Vowels** box,
                and consonants go in the **Consonants** box.
            </p>

            {letters.length > 0 ? (
                <div className={GameStyles.game_area}>
                    {/* Letter Tiles */}
                    <div className={GameStyles.letters_container}>
                        {letters.map((letter, index) => (
                            <div
                                key={index}
                                className={GameStyles.letter_tile}
                                draggable
                                onDragStart={(e) => handleDragStart(e, letter)}
                            >
                                {letter}
                            </div>
                        ))}
                    </div>

                    {/* Drop Zones */}
                    <div className={GameStyles.drop_zones}>
                        <div
                            className={GameStyles.drop_box}
                            onDrop={(e) => handleDrop(e, "vowel")}
                            onDragOver={allowDrop}
                        >
                            <h2 className={Styles.sub_heading}>Vowels</h2>
                        </div>
                        <div
                            className={GameStyles.drop_box}
                            onDrop={(e) => handleDrop(e, "consonant")}
                            onDragOver={allowDrop}
                        >
                            <h2 className={Styles.sub_heading}>Consonants</h2>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={GameStyles.score_display}>
                    <h2>Game Over!</h2>
                    <p>
                        Your score: {score} / {attempts}
                    </p>
                    <button
                        className={GameStyles.play_again_button}
                        onClick={reset_game}
                    >
                        Play Again
                    </button>
                </div>
            )}
        </div>
    );
};

export default AlphabetGame;
