const quiz_data = [
    { question: "I %% happy.", answer: "am" },
    { question: "She %% a student.", answer: "is" },
    { question: "They %% at school.", answer: "are" },
    { question: "I %% sleepy today.", answer: "am" },
    { question: "We %% ready to go.", answer: "are" },
    { question: "He %% a doctor.", answer: "is" },
    { question: "You %% very kind.", answer: "are" },
    { question: "I %% a teacher.", answer: "am" },
    { question: "The cat %% under the bed.", answer: "is" },
    { question: "My brother and I %% best friends.", answer: "are" },
    { question: "It %% a sunny day.", answer: "is" },
    { question: "The books %% on the table.", answer: "are" },
    { question: "She and I %% going to the park.", answer: "are" },
    { question: "You %% always on time.", answer: "are" },
    { question: "He %% my favorite teacher.", answer: "is" },
    { question: "They %% very excited about the trip.", answer: "are" },
    { question: "We %% at the party now.", answer: "are" },

    // Gradually shift to more nouns
    { question: "The dog %% very friendly.", answer: "is" },
    { question: "My sister %% a talented artist.", answer: "is" },
    { question: "The flowers %% beautiful.", answer: "are" },
    { question: "The cake %% delicious.", answer: "is" },
    { question: "The children %% playing outside.", answer: "are" },
    { question: "The team %% ready to start.", answer: "is" },
    { question: "The chairs %% broken.", answer: "are" },
    { question: "My parents %% proud of me.", answer: "are" },
    { question: "The weather %% perfect today.", answer: "is" },
    { question: "The train %% late.", answer: "is" },
    { question: "The apples %% fresh.", answer: "are" },
    { question: "My mother %% an excellent cook.", answer: "is" },
    { question: "The kids %% very polite.", answer: "are" },
    { question: "The house %% very clean.", answer: "is" },
    { question: "The papers %% on the desk.", answer: "are" },
    { question: "The car %% parked outside.", answer: "is" },
    { question: "The lamp %% on the table.", answer: "is" },
    { question: "The students %% ready for the test.", answer: "are" },
    { question: "The picture %% very colorful.", answer: "is" },
    { question: "The phones %% on silent mode.", answer: "are" },
    { question: "The window %% open.", answer: "is" },
    { question: "The books %% not in the bag.", answer: "are" },

    // More compound nouns and mixed subjects
    { question: "My brother and his friend %% playing soccer.", answer: "are" },
    { question: "The teacher and the principal %% in the office.", answer: "are" },
    { question: "My dog and my cat %% both asleep.", answer: "are" },
    { question: "The manager and her assistant %% preparing for the meeting.", answer: "are" },
    { question: "The box and the bag %% on the floor.", answer: "are" },
    { question: "The sun and the moon %% visible right now.", answer: "are" },
    { question: "The boy and the girl %% reading books.", answer: "are" },

    // Wrap-up with varied simple sentences
    { question: "The sky %% blue.", answer: "is" },
    { question: "The coffee %% hot.", answer: "is" },
    { question: "The chairs %% heavy.", answer: "are" },
    { question: "The bananas %% ripe.", answer: "are" },
    { question: "The baby %% sleeping.", answer: "is" },
    { question: "The road %% long.", answer: "is" },
    { question: "The birds %% singing.", answer: "are" },
    { question: "The car %% fast.", answer: "is" },
    { question: "The rooms %% clean.", answer: "are" },
    { question: "The beach %% beautiful.", answer: "is" },
    { question: "The mountains %% tall.", answer: "are" },
    { question: "The teacher %% very kind.", answer: "is" },
    { question: "The fire %% warm.", answer: "is" },
    { question: "The stars %% bright.", answer: "are" },
    { question: "The food %% tasty.", answer: "is" },
    { question: "The chairs %% arranged neatly.", answer: "are" },
    { question: "The sky %% cloudy.", answer: "is" },
    { question: "The team %% practicing hard.", answer: "is" },
    { question: "The books %% on the shelf.", answer: "are" },
    { question: "The clock %% ticking.", answer: "is" },
    { question: "The flowers %% blooming.", answer: "are" },
    { question: "The cat %% asleep on the chair.", answer: "is" },
    { question: "The boys %% excited to play.", answer: "are" },
    { question: "The girl %% writing a letter.", answer: "is" },
    { question: "The ice cream %% melting.", answer: "is" },
    { question: "The city %% very busy.", answer: "is" },
    { question: "The paintings %% beautiful.", answer: "are" },
    { question: "The stars %% shining brightly.", answer: "are" }
];

export default quiz_data;
