import React from "react";
import Styles from "./textbook_styles.module.css";
import JeffImage from "./textbook_images/01_jeff.jpg";
import MariaImage from "./textbook_images/01_maria.jpg";

const Chapter_01_paragraph_jeff = () => {
    return (
        <div className={Styles.container}>
            <h1 className={Styles.main_heading}>Paragraph - Jeff's Life</h1>

            <p className={Styles.indented_paragraph}>
                Hello, my name is Jeff. I am 22 years old, and I am from Kansas City. I live in a small apartment with
                my parents. Every day, I wake up at 7:00 in the morning. I have breakfast with my mom and dad, and then
                I go to work. I work full-time at a café from 9:00 AM to 3:00 PM. I really enjoy my job because I get to
                meet a lot of new people.
            </p>

            <p className={Styles.indented_paragraph}>
                In my free time, I like to play guitar and read books. On weekends, I often go hiking with my friends or
                watch movies at home. In the evening, I have dinner with my family, and we watch TV together. I go to
                bed at 10:00 PM because I like to get up early and start the day feeling rested.
            </p>

            <img className={Styles.example_image} src={JeffImage}/>

            <h2 className={Styles.sub_heading}>1. Sentence Elements: Capital Letters</h2>

            <p className={Styles.paragraph}>
                Every sentence should begin with a capital letter. For example:
            </p>

            <ul className={Styles.list}>
                <li><span className={Styles.red_underline}>H</span>ello, my name is Jeff.</li>
                <li><span className={Styles.red_underline}>I</span> am 22 years old, and I am from Kansas City.</li>
                <li><span className={Styles.red_underline}>E</span>very day, I wake up at 7:00 in the morning.</li>
            </ul>

            <p className={Styles.paragraph}>
                Names are also capitalized: Names of people (Jeff) and names of places (Kansas City).
            </p>

            <ul className={Styles.list}>
                <li>Hello, my name is <span className={Styles.red_underline}>J</span>eff.</li>
                <li>I am 22 years old, and I am from <span className={Styles.red_underline}>K</span>ansas <span
                    className={Styles.red_underline}>C</span>ity.
                </li>
            </ul>

            <p className={Styles.paragraph}>
                The word "I" is always capitalized, even if it is not at the beginning of a sentence. For example:
            </p>

            <ul className={Styles.list}>
                <li>Every day, <span className={Styles.red_underline}>I</span> wake up at 7:00 <span
                    className={Styles.red_underline}>i</span>n
                    the morning.
                </li>
            </ul>

            <p className={Styles.paragraph}>
                The word "I" is capitalized, even though it isn't the first word of the sentence. But the "I" in the
                word "in" is NOT capitalized, because it is not the word "I", but just a letter in a different word.
            </p>

            <h2 className={Styles.sub_heading}>2. Sentence Elements: Punctuation</h2>

            <p className={Styles.paragraph}>
                Every sentence must end with a punctuation marks. There are three punctuation marks for the end of
                sentences:
            </p>

            <p className={Styles.paragraph}>
                . PERIOD - This is the normal ending punctuation mark.
            </p>

            <ul className={Styles.list}>
                <li>Jeff lives with his family<span className={Styles.red_underline}>.</span></li>
            </ul>

            <p className={Styles.paragraph}>
                ? QUESTION MARK - This is for questions.
            </p>

            <ul className={Styles.list}>
                <li>Where do you live<span className={Styles.red_underline}>?</span></li>
            </ul>

            <p className={Styles.paragraph}>
                ! EXCLAMATION POINT - This is for exclamations.
            </p>

            <ul className={Styles.list}>
                <li>That is great<span className={Styles.red_underline}>!</span></li>
            </ul>

            <h2 className={Styles.sub_heading}>3. Sentence Elements - Subject & Verb</h2>

            <p className={Styles.paragraph}>
                Every sentences must have a SUBJECT and a VERB.
            </p>

            <h2 className={Styles.section_heading}>Verbs</h2>

            <p className={Styles.paragraph}>
                Usually the VERB is the action of a sentence:
            </p>

            <ul className={Styles.list}>
                <li>I <span className={Styles.red_underline}>live</span> in a small apartment with my parents.</li>
                <li>I <span className={Styles.red_underline}>work</span> full-time at a café from 9:00 AM to 3:00 PM.
                </li>
            </ul>

            <p className={Styles.paragraph}>
                Some sentences have 2 or more verbs:
            </p>

            <ul className={Styles.list}>
                <li>On weekends, I often <span className={Styles.red_underline}>go</span> hiking with my friends
                    or <span className={Styles.red_underline}>watch</span> movies at home.
                </li>
            </ul>

            <p className={Styles.paragraph}>
                The BE verb doesn't show an action, but it IS a verb. The Be verb has 3 present tense forms: AM, IS,
                ARE.
            </p>

            <ul className={Styles.list}>
                <li>Hello, my name <span className={Styles.red_underline}>is</span> Jeff.</li>
                <li>I <span className={Styles.red_underline}>am</span> 22 years old.</li>
            </ul>

            <h2 className={Styles.section_heading}>Subjects</h2>

            <p className={Styles.paragraph}>
                The SUBJECT of a sentence is usually the PERSON or THING doing the action of the verb. In the next
                sentence, "LIVES" is the verb. Who is living? Jeff is living. So, "Jeff" is the subject of the sentence.
            </p>

            <ul className={Styles.list}>
                <li><span className={Styles.subject}>Jeff</span> <span className={Styles.verb}>lives</span> with his
                    parents.
                </li>
            </ul>

            <p className={Styles.paragraph}>
                In this paragraph, since Jeff is talking about himself, the subject will usually be "I".
            </p>

            <ul className={Styles.list}>
                <li>In my free time, <span className={Styles.subject}>I</span> <span className={Styles.verb}>like</span> to play guitar and read books.</li>
                <li><span className={Styles.subject}>I</span> <span className={Styles.verb}>live</span> in a small apartment with my parents.</li>
                <li><span className={Styles.subject}>I</span> <span className={Styles.verb}>am</span> 22 years old.</li>
            </ul>
        </div>
    );
};

export default Chapter_01_paragraph_jeff;