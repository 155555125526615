import React, {useEffect, useState} from "react";
import Styles from "./textbook_styles.module.css";
import HeaderImage from "../../images/voyager.jpg";
import WritingImportance from "./textbook_ch01_unit01_importance-of-writing";
import QuizModule from "../../quiz_module";
import QuizData from "./quiz_data_be_verb";
import TableOfContents from "../table_of_contents";
import TOCData from "./toc_data";
import Alphabet from "./alphabet";
import CourseOverview from "./course_overview";
import CHAPTER_01_PARAGRAPH_JEFF from "./chapter_01_paragraph_jeff";
import Chapter_01_be_verb_present_tense from "./chapter_01_be_verb_present_tense";

const WritingTextbook = () => {
    const [textbookUrl, setTextbookUrl] = useState('');

    const handle_toc_selection = (url) => {
        setTextbookUrl(url);
    }
    const load_selection = () => {
        console.log(textbookUrl);

        switch(textbookUrl) {
            case "why-writing-is-important":
                return <WritingImportance />;
            case "alphabet":
                return <Alphabet />
            case "course-schedule":
                return <CourseOverview />
            case "chapter_01_jeff":
                return <CHAPTER_01_PARAGRAPH_JEFF />
            case "chapter_01_be_verb_present_tense":
                return <Chapter_01_be_verb_present_tense />
            case "chapter_01_be_verb_negatives":
                return <></>
            case "chapter_01_be_verb_questions":
                return <></>
            default:
                return <></>;
        }
    }


    return (
        <>
            <TableOfContents
                toc_data={TOCData}
                handle_select={handle_toc_selection}
            />
            {load_selection()}
            {true ? '' : <QuizModule quizData={QuizData} isLoggedIn={true} userName={"Michael"} />}
        </>
    );
};

export default WritingTextbook;
