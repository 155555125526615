import React, {useEffect, useState } from "react";
import Styles from "../styles/ofbstyles.module.css";
import FStyles from "../styles/finance.module.css";
import {talk, format_currency} from "../ofb_util";

const Transaction = (props) => {

    const [showDetails, setShowDetails] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState({
        height:window.innerHeight,
        width:window.innerWidth
    })

    useEffect(() => {
        window.addEventListener('resize', handle_resize);
    })
    const initiate_edit = (event) => {
        if(event) {
            event.stopPropagation();
        }
        props.edit_handler(props.t);
    }
    const process_amount = (amount) => {
        amount = (amount / 100).toFixed(2);
        if(amount > 0) {
            return '$' + format_currency(amount);
        } else {
            return '$-' + format_currency(Math.abs(amount));
        }
    }
    function handle_resize() {
        setWindowDimensions({
            height:window.innerHeight,
            width:window.innerWidth
        })
    }
    const truncate_date = (date) => {
        return date.substring(0, date.length - 3);
    }
    const handle_transaction_click = () => {
        if(!props.disabled) {
            setShowDetails(!showDetails);
        }
    }
    const mark_cleared = (event) => {
        if(event) {
            event.stopPropagation();
        }
        props.change_reconciliation(props.t.transaction_id, 'CLEARED');
    }
    const mark_not_cleared = (event) => {
        if(event) {
            event.stopPropagation();
        }
        props.change_reconciliation(props.t.transaction_id, 'NOT_RECONCILED');
    }
    const delete_transaction = (event) => {
        if(event) {
            event.stopPropagation();
        }
        props.delete_transaction(props.t);
    }

    return <div
            className={props.disabled ? FStyles.ofb_finance_transaction_disabled : Styles.ofb_finance_transaction}
            onClick={handle_transaction_click}
        >
        <div className={Styles.ofb_finance_transaction_row}>
            <div className={Styles.ofb_transaction_date_cell}>{
                (window.innerWidth > 750) ?
                    props.t.transaction_date : truncate_date(props.t.transaction_date)
            }</div>
            <div className={Styles.ofb_transaction_payee_cell}>{props.t.payee_name}</div>
        </div>
        <div className={Styles.ofb_finance_transaction_detail_row}>
            <div className={Styles.ofb_transaction_reconciled_cell}>{props.t.reconciliation_status}</div>
            <div className={Styles.ofb_transaction_category_cell}>
                {(props.t.transaction_parts.length === 1) ? (props.t.transaction_parts[0]['category_name'].length > 0 ? props.t.transaction_parts[0]['category_name'] : props.t.transaction_parts[0]['transfer_account_name']) :
                    <span className={Styles.ofb_split_text}>-split-</span>
                }
            </div>
            <div className={Styles.ofb_transaction_memo_cell} title={props.t.memo}>{props.t.memo}</div>
            <div
                className={props.t.total > 0 ? `${Styles.ofb_dark_green} ${Styles.ofb_bold} ${Styles.ofb_transaction_amount_cell}` : `${Styles.ofb_transaction_amount_cell}`} title="Transaction Amount">{process_amount(props.t.total)}</div>
            <div
                className={props.is_parent_account ? Styles.ofb_transaction_parent_balance_cell : Styles.ofb_hide}
                title="Parent Account Balance"
            >
                {process_amount(props.t.parent_balance)}
            </div>
            <div className={Styles.ofb_transaction_balance_cell} title="This Account Balance">{process_amount(props.t.balance)}</div>
        </div>
        {props.t.transaction_parts.length > 1 ? (
            <div className={Styles.ofb_transaction_split_holder_row}>
                {props.t.transaction_parts.map(tpart => (
                    <div className={Styles.ofb_transaction_split_row} key={tpart.transaction_part_id}>
                        <div className={Styles.ofb_transaction_split_category}>{tpart.category_name.length > 0 ? tpart.category_name : tpart.transfer_account_name}</div>
                        <div className={Styles.ofb_transaction_split_amount}>{process_amount(tpart.amount)}</div>
                    </div>
                ))}
            </div>
        ) : ''}
        <div className={showDetails ? Styles.ofb_finance_transaction_hidden_detail_row : Styles.ofb_hide}>
            <div><i>TransactionID</i>: {props.t.transaction_id}</div>
            <div>
                <i>Memo</i>: {props.t.memo}
            </div>
            <div>
                <button onClick={event => delete_transaction(event)}>Delete this Transaction</button>
                <button onClick={event => mark_cleared(event)}>Mark Cleared</button>
                <button onClick={event => mark_not_cleared(event)}>Mark Not Cleared</button>
                <button onClick={(event) => initiate_edit(event)}>Edit</button>
            </div>
        </div>

    </div>
}

export default Transaction