import React from "react";
import Styles from "./textbook_styles.module.css"; // Assuming the earlier stylesheet is named this way
import Journaling from "./textbook_images/ch01_journaling.jpg";
import GroupWork from "./textbook_images/ch01_group.jpg";
import WritingTypes from "./textbook_images/ch01_writingtypes.jpg";


const WritingImportance = () => {
    return (
        <div className={Styles.container}>
            <h1 className={Styles.main_heading}>Why Writing is Important</h1>

            <p className={Styles.paragraph}>
                Writing is a very important skill for communication. It helps you share your ideas, tell your stories, and connect with people.
                Writing can also help you in your daily life, at school, and at work.
            </p>

            <img
                src={Journaling}
                alt="A person sitting at a desk writing in a notebook, symbolizing creativity and self-expression."
                className={Styles.example_image}
            />

            <h2 className={Styles.sub_heading}>How Writing Helps You</h2>
            <p className={Styles.paragraph}>
                Writing helps you in many ways:
            </p>
            <ul className={Styles.list}>
                <li>It improves your thinking. When you write, you organize your ideas and make them clear.</li>
                <li>It helps you learn new words and grammar.</li>
                <li>You can share your feelings and experiences with others.</li>
                <li>Writing builds your confidence because it shows your progress.</li>
            </ul>

            <img
                src={GroupWork}
                alt="A group of students working together on a writing project, showing teamwork and collaboration."
                className={Styles.example_image}
            />

            <h2 className={Styles.sub_heading}>Different Kinds of Writing</h2>
            <p className={Styles.paragraph}>
                There are many types of writing you will use in life:
            </p>
            <ul className={Styles.list}>
                <li><strong>Emails:</strong> Writing emails is important for school, work, and staying in touch with friends or family.</li>
                <li><strong>Stories:</strong> You can write stories to share your imagination and creativity.</li>
                <li><strong>Notes:</strong> Writing notes helps you remember important information.</li>
                <li><strong>Messages:</strong> Short messages or texts are a quick way to communicate.</li>
                <li><strong>Essays:</strong> At school, writing essays helps you explain your ideas and learn new things.</li>
            </ul>

            <img
                src={WritingTypes}
                alt="A variety of writing examples, such as an email, a story, and a list, to show the many uses of writing."
                className={Styles.example_image}
            />

            <h2 className={Styles.section_heading}>Start Writing Today!</h2>
            <p className={Styles.paragraph}>
                Writing is like any other skill: the more you practice, the better you will become. Start by writing small things, like your daily routine or a note to a friend. With time, you can write longer and more creative texts. Writing will open new doors for you, so start today!
            </p>
        </div>
    );
};

export default WritingImportance;
