import React from "react";
import Styles from "./textbook_styles.module.css";
import QuizModule from "../../quiz_module";
import QuizData from "./quiz_data_be_verb";


const Chapter_01_be_verb_present_tense = () => {
    return (
        <div className={Styles.container}>
            <h1 className={Styles.main_heading}>The BE Verb - Present Tense</h1>

            {/* Introduction */}
            <p className={Styles.paragraph}>
                The **BE** verb is one of the most important verbs in English. In the present tense, it has three forms:
                <span className={Styles.red_underline}> am</span>, <span className={Styles.red_underline}>is</span>, and <span className={Styles.red_underline}>are</span>. Use it to talk about what something is, where it is, or how someone feels.
            </p>

            {/* Forms of BE Verb */}
            <h2 className={Styles.sub_heading}>Forms of BE Verb</h2>
            <ul className={Styles.list}>
                <li><strong>I</strong> **am** a student.</li>
                <li><strong>You/We/They</strong> **are** happy.</li>
                <li><strong>He/She/It</strong> **is** at school.</li>
            </ul>

            {/* Examples */}
            <h2 className={Styles.sub_heading}>Examples</h2>
            <ul className={Styles.list}>
                <li>She <span className={Styles.red_underline}>is</span> a teacher.</li>
                <li>I <span className={Styles.red_underline}>am</span> happy.</li>
                <li>They <span className={Styles.red_underline}>are</span> in the house.</li>
            </ul>

            {/* Explanation */}
            <h2 className={Styles.sub_heading}>How to Use the BE Verb</h2>
            <p className={Styles.paragraph}>
                Use the correct form of the BE verb depending on the subject:
            </p>
            <table className={Styles.table}>
                <thead>
                <tr>
                    <th>Subject</th>
                    <th>BE Verb</th>
                    <th>Example</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>I</td>
                    <td>am</td>
                    <td>I am a student.</td>
                </tr>
                <tr>
                    <td>You / We / They</td>
                    <td>are</td>
                    <td>You are kind. / We are friends.</td>
                </tr>
                <tr>
                    <td>He / She / It</td>
                    <td>is</td>
                    <td>She is a doctor.</td>
                </tr>
                </tbody>
            </table>

            {/* Practice Section */}
            <h2 className={Styles.sub_heading}>Practice</h2>
            <p className={Styles.paragraph}>Complete these sentences with <span className={Styles.red_underline}>am</span>, <span className={Styles.red_underline}>is</span>, or <span className={Styles.red_underline}>are</span>:</p>
            <QuizModule quizData={QuizData} isLoggedIn={true} userName={"Michael"} />

            {/* Video Placeholder */}
            <div className={Styles.video_placeholder}>
                <p>Insert video here: "How to Use the Present Tense BE Verb"</p>
            </div>

            {/* Encouragement */}
            <h2 className={Styles.sub_heading}>Keep Practicing!</h2>
            <p className={Styles.paragraph}>
                The BE verb is everywhere in English. Practice using it in sentences every day. For example, write about how you feel, what you do, and where you are.
            </p>
        </div>
    );
};

export default Chapter_01_be_verb_present_tense;
